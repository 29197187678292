import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircleVector from '../../../assets/Circle-Vector.png';
import MemoErrorIcon from '../../svg-icons/ErrorIcon';

interface Props {
  open?: boolean;
  errorTitle: string;
  errorMessage?: string;
  children?: JSX.Element;
  backdropStyle?: { [key: string]: any };
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '540px',
  bgcolor: 'rgba(16, 18, 31)',
  borderRadius: '24px',
  padding: '64px 32px 32px 32px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  outline: 'none',
};

const IconHeader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: '150px',
      height: '150px',
      top: '-75px',
      left: '50%',
      transform: 'translateX(-50%)',
    }}
  >
    <div
      style={{
        position: 'absolute',
        width: '150px',
        height: '75px',
        borderRadius: '75px 75px 0 0',
        backgroundColor: 'rgb(16, 18, 31)',
        top: '0',
        zIndex: -1,
      }}
    />
    <img alt='Circle Vector' src={CircleVector} width={100} height={100} />
    <MemoErrorIcon
      color='white'
      height={36}
      width={36}
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    />
  </div>
);

const ErrorModal = ({
  open = true,
  errorTitle,
  errorMessage,
  children,
  backdropStyle,
}: Props) => {
  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
            ...backdropStyle,
          },
        },
      }}
    >
      <Box sx={modalStyle}>
        <IconHeader />
        <Typography
          variant='h2'
          style={{
            fontSize: '24px',
            fontWeight: '700',
            marginBottom: '25px',
            textAlign: 'center',
          }}
        >
          {errorTitle}
        </Typography>
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '30px',
            textAlign: 'center',
          }}
        >
          {errorMessage}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
};

export default ErrorModal;
