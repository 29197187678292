import { useState, useEffect, useCallback } from 'react';

interface CountdownActions {
  startTimer: () => void;
  resetTimer: () => void;
}

export const useCountdown = (
  targetTime: number,
  onTimeUp: () => void,
): [number, CountdownActions, boolean] => {
  const [timeLeft, setTimeLeft] = useState(targetTime);
  const [isRunning, setIsRunning] = useState(false);

  const startTimer = (): void => {
    setIsRunning(true);
  };

  const resetTimer = useCallback((): void => {
    setIsRunning(false);
    setTimeLeft(targetTime);
  }, [targetTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const decrementInSeconds = 0.2; // In seconds.
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime: number) => {
          const remainingTime =
            Math.round((prevTime - decrementInSeconds) * 10) / 10; // To one decimal place.
          return remainingTime < 0 ? 0 : remainingTime;
        });
      }, decrementInSeconds * 1000);
    } else if (isRunning && timeLeft <= 0) {
      resetTimer();
      onTimeUp();
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning, timeLeft, onTimeUp, resetTimer]);

  return [timeLeft, { startTimer, resetTimer }, isRunning];
};
