import { Box, LinearProgress, Typography } from '@mui/material';
import {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DexieContext } from '../../context/dexie.context';
import { performanceLogger } from '../../helpers';

// Build expiry for cache invalidation
const BUILD_EXPIRY_IN_SEC = 24 * 7 * 3600 * 52; // one year

interface Props {
  unityVersion: string;
  systemConfig: { [index: string]: any };
  renderPlayer: (
    loaderUrl: string,
    dataUrl: string,
    frameworkUrl: string,
    codeUrl: string,
    setUnityLoadingProgress: (progress: number) => void,
  ) => ReactElement;
}

const DownloadBuildWrapper = (props: Props) => {
  let unityBuild =
    process.env.REACT_APP_WEBGL_CDN + process.env.REACT_APP_UNITY_VERSION;
  if (props.unityVersion && unityBuild) {
    // eslint-disable-next-line no-useless-escape
    unityBuild = unityBuild.replace(/\/[^\/]*$/, `/${props.unityVersion}`);
  }

  const initialDownloadRef = useRef(false);
  const initialAppExecutionRef = useRef(false);

  const { db } = useContext(DexieContext);

  const [loaderUrl, setLoaderUrl] = useState<string>('');
  const [dataUrl, setDataUrl] = useState<string>('');
  const [frameworkUrl, setFrameworkUrl] = useState<string>('');
  const [codeUrl, setCodeUrl] = useState<string>('');
  const [loadedByteLength, setLoadedByteLength] = useState<number>(0);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState<number>(0);
  const [loadingMessage, setLoadingMessage] = useState<string>(
    'Hang tight! Crafting your next-gen learning adventure....',
  );
  const logo =
    props.systemConfig?.talespinStreamingPlayer?.whiteLabel?.images
      ?.companyLogo2;
  const [contentLengths, setContentLengths] = useState<{
    [key: string]: number;
  }>({
    loader: Infinity,
    data: Infinity,
    framework: Infinity,
    code: Infinity,
  });

  const totalByteLength = useMemo(() => {
    return Object.keys(contentLengths).reduce(
      (prev, key) => prev + contentLengths[key],
      0,
    );
  }, [contentLengths]);

  const progressPercentage = useMemo(() => {
    const downloadProgress = (loadedByteLength / totalByteLength) * 100;
    const unityProgress = unityLoadingProgress * 100;

    if (downloadProgress >= 100) {
      setLoadingMessage('Loading...thanks for your patience while we gear up!');
      return Math.round(unityProgress);
    }

    return Math.round(downloadProgress + unityProgress);
  }, [loadedByteLength, totalByteLength, unityLoadingProgress]);

  const downloadComplete = useMemo(() => {
    return Boolean(loaderUrl && dataUrl && codeUrl && frameworkUrl);
  }, [loaderUrl, dataUrl, codeUrl, frameworkUrl]);

  useEffect(() => {
    const checkFileExpiry = async (
      cachedBuild: any,
      buildUrl: string,
      urlObject: any,
    ): Promise<void> => {
      // Fetch Headers only
      const response = await fetch(`${buildUrl}${urlObject.urlExtension}`, {
        method: 'HEAD',
      });
      // Check response for Error
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // Get LastModified-Date from Server-File
      const lastModifiedDate = response.headers.get('Last-Modified');
      if (lastModifiedDate !== null) {
        var date = new Date(Date.parse(lastModifiedDate));
        // Get LastModified-Date on Cached File
        var cachedDate = cachedBuild[urlObject.dbKey].modifiedDate;
        // Check Modified Date of remote against local
        const diffInSec = Math.abs(cachedDate - date.getTime()) / 1000;
        if (diffInSec <= BUILD_EXPIRY_IN_SEC) {
          const contentLength = parseInt(
            response.headers.get('Content-Length') ?? '0',
          );
          // Cache is valid. Set content length to indicate progress
          setContentLengths((prev) => ({
            ...prev,
            [urlObject.dbKey]: contentLength,
          }));
          setLoadedByteLength((prev) => prev + contentLength);
          urlObject.cached = true;
          // Set URL for object
          urlObject.function(
            URL.createObjectURL(cachedBuild[urlObject.dbKey].blobData),
          );
        }
      }
    };

    const fetchUnityFiles = async (
      urls: Array<any>,
      unityBuild: string,
    ): Promise<void> => {
      return Promise.all(
        urls.map(async (url: any) => {
          const response = await fetch(`${unityBuild}${url.urlExtension}`);
          if (!response.ok) {
            console.error(response.statusText);
            throw new Error(response.statusText);
          }
          const contentLength = parseInt(
            response.headers.get('Content-Length') ?? '0',
          );

          setContentLengths((prev) => ({
            ...prev,
            [url.dbKey]: contentLength,
          }));

          if (!response.body) throw new Error('Failed to catch response body');

          const reader = response.body.getReader();
          let chunks = [];
          let received = 0;
          // Read response stream to extract data chunks and progress
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            chunks.push(value);
            setLoadedByteLength((prev) => prev + value.length);
            received += value.length;
          }

          let body = new Uint8Array(received);
          let position = 0;
          // Concat chunks into single array
          for (let chunk of chunks) {
            body.set(chunk, position);
            position += chunk.length;
          }

          const lastModifiedDate = response.headers.get('Last-Modified')!;
          const contentType = response.headers.get('Content-Type')!;
          const data = new Blob([body], { type: contentType });

          return {
            [url.dbKey]: {
              modifiedDate: new Date(Date.parse(lastModifiedDate)).getTime(),
              blobData: data,
              setUrl: url.function,
            },
          };
        }),
      )
        .then(async (responses) => {
          // Keep current values in db.build, but overwrite any that exist in responses
          let build = await db.build.get(unityBuild);
          if (build === null || build === undefined) {
            build = {};
          }
          // Clear current build cache in DB
          await db.build.clear();

          // Add/Overwrite default Properties
          build.id = unityBuild;
          build.modifiedAt = new Date().getTime();
          const buildData = Object.assign({}, ...responses);
          // Replace any modified files
          for (const property in buildData) {
            build[property] = {
              modifiedDate: buildData[property].modifiedDate,
              blobData: buildData[property].blobData,
            };
          }
          // Store in DB
          await db.build.add(build);
          // Run Set-Functions for modified files
          for (const property in buildData) {
            // Call SetUrl-Function that was stored in Url-Object
            buildData[property].setUrl(
              URL.createObjectURL(build[property].blobData),
            );
          }
        })
        .catch((error) => {
          console.error('Failed to retrieve unity data', error);
        });
    };

    const downloadUnityBuild = async () => {
      const urls = [
        {
          urlExtension: '/Build/WebGL.loader.js',
          dbKey: 'loader',
          cached: false,
          function: setLoaderUrl,
        },
        {
          urlExtension: '/Build/WebGL.data.unityweb',
          dbKey: 'data',
          cached: false,
          function: setDataUrl,
        },
        {
          urlExtension: '/Build/WebGL.framework.js.unityweb',
          dbKey: 'framework',
          cached: false,
          function: setFrameworkUrl,
        },
        {
          urlExtension: '/Build/WebGL.wasm.unityweb',
          dbKey: 'code',
          cached: false,
          function: setCodeUrl,
        },
      ];
      const build = await db.build.get(unityBuild);
      if (unityBuild.startsWith('http') && build && unityBuild === build.id) {
        // Local Build of current version is available
        // Check Server for LastModified-Date of each file
        var promises = [];
        for (let i = 0; i < urls.length; i++) {
          promises.push(checkFileExpiry(build, unityBuild, urls[i]));
        }
        // Check files
        await Promise.all(promises).catch((error) => {
          console.error('Failed whilst checking cached unity data', error);
        });
        // Remove all that are finished
        var filteredUrls = urls.filter((value) => !value.cached);
        // Start download for required files
        if (filteredUrls.length) {
          await fetchUnityFiles(filteredUrls, unityBuild).catch((error) => {
            console.error('Failed to retrieve unity data', error);
          });
        }
      } else {
        await fetchUnityFiles(urls, unityBuild).catch((error) => {
          console.error('Failed to retrieve unity data', error);
        });
      }
    };
    downloadUnityBuild();
    // Disable warning for dependencies. Adding the dependencies will cause recurring calls,
    // and removing them will cause invalid behaviour for the progressbar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (downloadComplete) {
    if (!initialAppExecutionRef.current) {
      performanceLogger('user starts executing app');
      initialAppExecutionRef.current = true;
      performanceLogger('user starts executing app');
      initialAppExecutionRef.current = true;
    }
  } else if (!initialDownloadRef.current) {
    performanceLogger('user downloading app if needed');
    initialDownloadRef.current = true;
  }

  return (
    <>
      {progressPercentage < 100 || !downloadComplete ? (
        <div className='loading-with-progress'>
          {logo && (
            <img
              src={logo?.src}
              height={45}
              style={{ marginBottom: '3.3rem' }}
              alt={logo?.alt}
              className='talespin-logo-img'
            />
          )}
          <div
            className='loading-with-progress-content'
            style={{ width: '400px' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography fontSize='small'>{loadingMessage}</Typography>
              <Typography fontSize='small'>{progressPercentage}%</Typography>
            </Box>
            <LinearProgress
              color='inherit'
              variant='determinate'
              value={progressPercentage}
            />
          </div>
        </div>
      ) : null}
      {downloadComplete
        ? props.renderPlayer(
            loaderUrl,
            dataUrl,
            frameworkUrl,
            codeUrl,
            setUnityLoadingProgress,
          )
        : null}
    </>
  );
};

export default DownloadBuildWrapper;
