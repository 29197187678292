import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Toolbar from '@mui/material/Toolbar';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';
import { logout } from '../../utils';
import DownArrowIcon from '../svg-icons/DownArrowIcon';
import FullScreenIcon from '../svg-icons/FullScreenIcon';
import MinimizeScreenIcon from '../svg-icons/MinimizeScreenIcon';

// Import components.
import { ClickAwayListener, Grow, List, Popper } from '@mui/material';
import BrandingObj from '../../assets/Branding';
import { StreamingPlayerStatus } from '../../types/StreamingPlayerStatus';
import { UserUrls } from '../../types/UserUrls';
import Copyright from '../Copyright';
import MemoTalespinHorizontalLogo from '../svg-icons/TalespinHorizontalLogo';

const rxd = (window as any).RXD;

const buildNumber = process.env.REACT_APP_BUILD_NUMBER || '';

const unityVersion = process.env.REACT_APP_UNITY_VERSION || '';


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // transition: theme.transitions.create(['margin', 'width'], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  // Override media queries injected by theme.mixins.toolbar
  '@media (min-width: 600px)': {
    minHeight: 64,
  },
  backgroundImage: 'none',
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4d4f5a',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4d4f5a',
    padding: '2px 12px',
  },
}));

interface Props {
  contentTitle: string;
  playerStatus?: StreamingPlayerStatus;
  fullscreenExternal?: boolean;
  setFullscreenExternal?: (maxScreenExt: boolean) => void;
  orgId: string;
  userRole: string | null;
  forceSendRXDEvents: (startPollingAfter?: boolean) => Promise<void>;
  systemConfig: { [index: string]: any };
  userUrls: UserUrls | null;
  showPlayerControls: boolean;
}

const Navigation = (props: Props) => {
  const [open, setOpen] = useState(false);
  const isWhiteLabel =
    !!!props?.systemConfig?.talespinStreamingPlayer?.whiteLabel?.isDefaults;
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const userDetails = JSON.parse(sessionStorage.getItem('user') || '{}');
  const displayName =
    userDetails?.displayName || userDetails?.username || userDetails?.email;

  function getInitials(name: string) {
    return name
      .trim()
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  }

  useEffect(() => {
    let toolbarTimeout: any = null;
    // Clear toolbarTimeout from previous session.
    const clearToolbarTimeout = () => {
      if (toolbarTimeout) {
        clearTimeout(toolbarTimeout);
        toolbarTimeout = null;
      }
    };

    const showToolbar = () => {
      const toolbar = document.getElementById('custom-toolbar');
      if (toolbar) {
        toolbar.style.display = 'flex';
      }
    };

    // Set timeout to fade away the toolbar.
    const setToolbarVisibility = (event: any) => {
      const delay = 1500;

      // Clear any previously set timeout that might still be running.
      clearToolbarTimeout();

      const toolbar = document.getElementById('custom-toolbar');
      if (event.y < 20 && toolbar) {
        if (toolbar.style.display === 'none') {
          // Show player's controls.
          setTimeout(() => {
            // Add small delay on 'pointermove' before showing the toolbar.
            showToolbar();
          }, 150);
        }
      }

      if (event.y > 60 && toolbar) {
        if (toolbar.style.display === 'flex') {
          // Set the timeout to hide player's controls.
          toolbarTimeout = setTimeout(() => {
            toolbar.style.display = 'none';
          }, delay);
        }
      }
    };

    if (props.fullscreenExternal) {
      document.addEventListener('pointermove', setToolbarVisibility);
    }

    // Do clean up.
    return () => {
      // Clear any toolbar timeout that might still be running.
      clearToolbarTimeout();
      document.removeEventListener('pointermove', setToolbarVisibility);
    };
  }, [props.fullscreenExternal]);

  const handleMenuToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleMenuClose = (event: MouseEvent | TouchEvent) => {
    if (menuAnchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // Return focus to the button when we transitioned from open to closed
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      menuAnchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const userRole = props?.userRole ?? '';

  const drawerItems = [
    // ...(userRole.indexOf('user')
    //     ? [
    //           {
    //               text: 'Open Passport',
    //               onClick: () => openInNewTab(`https://${props.orgId}.passport.talespin.io/`),
    //           },
    //       ]
    //     : []),
    ...(userRole.indexOf('user') < 0
      ? [
          {
            text: 'Open Talespin Platform',
            onClick: () => openInNewTab(`${props?.userUrls?.runwayUrl}`),
          },
        ]
      : []),
    ...(!sessionStorage.getItem('isSettoptokenAuth')
      ? [
          {
            text: 'Log Out',
            onClick: () => {
              logout();
            },
          },
        ]
      : rxd
        ? [
            {
              text: 'Exit Course',
              onClick: async () => {
                // Send any RXD events before logout and exit.
                if (rxd) {
                  await props?.forceSendRXDEvents(false);
                }

                setTimeout(() => {
                  logout();
                  rxd?.ConcedeControl();
                  console.log('RXD: ConcedeControl() called');
                }, 1000);
              },
            },
          ]
        : []),
  ];

  return (
    <Box sx={{ display: 'flex', width: '100vw' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <CustomToolbar
          id='custom-toolbar'
          style={{ display: props.fullscreenExternal ? 'none' : 'flex' }}
        >
          <Typography variant='h6' noWrap sx={{ flexGrow: 1 }} component='div'>
            {isWhiteLabel ? (
              ''
            ) : (
              <Box sx={{ display: 'flex' }}>
                <MemoTalespinHorizontalLogo color='white' />
                <Box sx={{ paddingLeft: '15px', fontWeight: 'bold' }}>
                  {BrandingObj.authHeader.headingText}
                </Box>
              </Box>
            )}
          </Typography>

          {props.showPlayerControls &&
            (props.fullscreenExternal ? (
              <CustomTooltip
                title='Exit Fullscreen'
                enterDelay={1000}
                enterNextDelay={1000}
              >
                <IconButton
                  color='inherit'
                  aria-label='exit fullscreen'
                  edge='end'
                  onClick={() => {
                    if (props.setFullscreenExternal)
                      props.setFullscreenExternal(false);
                  }}
                  sx={{
                    '&:hover': { color: '#FFC440' },
                  }}
                >
                  <MinimizeScreenIcon color='white' style={{ fontSize: 40 }} />
                </IconButton>
              </CustomTooltip>
            ) : (
              <CustomTooltip
                title='Fullscreen'
                enterDelay={1000}
                enterNextDelay={1000}
              >
                <IconButton
                  color='inherit'
                  aria-label='fullscreen'
                  edge='end'
                  onClick={() => {
                    if (props.setFullscreenExternal)
                      props.setFullscreenExternal(true);
                  }}
                  sx={{
                    '&:hover': { color: '#FFC440' },
                  }}
                >
                  <FullScreenIcon color='white' style={{ fontSize: 40 }} />
                </IconButton>
              </CustomTooltip>
            ))}
          <Box
            onClick={handleMenuToggle}
            sx={{
              color: 'common.white',
            }}
          >
            <CustomTooltip
              title={open ? '' : 'Open Menu'}
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <IconButton
                color='inherit'
                aria-label='open menu'
                edge='end'
                ref={menuAnchorRef}
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                disableRipple
              >
                <div
                  style={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#459ED6',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '0.5rem',
                    marginLeft: '1.5rem',
                  }}
                >
                  <Typography fontSize='small'>
                    {getInitials(displayName)}
                  </Typography>
                </div>
                <Typography
                  fontSize='medium'
                  sx={{
                    '&:hover': {
                      color: '#BBD4DD',
                      backgroundColor: 'transparent',
                    },
                    marginRight: '1rem',
                  }}
                >
                  {displayName}
                </Typography>
                <DownArrowIcon color='#A3A5AD' style={{ fontSize: 40 }} />
              </IconButton>
            </CustomTooltip>
          </Box>
        </CustomToolbar>
        <Popper
          open={open}
          anchorEl={menuAnchorRef.current}
          role={undefined}
          placement='bottom-start'
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'right bottom' : 'right top',
              }}
            >
              <Box sx={{ backgroundColor: 'primary.light' }}>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList>
                    {drawerItems.map((item, index) => {
                      const { text, onClick } = item;
                      return (
                        <MenuItem
                          key={index}
                          onClick={onClick}
                          sx={{
                            padding: 3,
                            py: '.1875rem',
                            '&:hover': {
                              color: '#BBD4DD',
                              backgroundColor: 'rgba(244, 244, 244, 0.25)',
                            },
                          }}
                          disableRipple
                        >
                          <ListItemText primary={text} />
                        </MenuItem>
                      );
                    })}
                    <List sx={{ padding: '0 24px', color: 'secondary.main' }}>
                      <Divider sx={{ margin: '8px 0' }} />
                      <ListItemText sx={{ margin: 0, height: '28px' }}>
                        Org ID:{' '}
                        <strong>{BrandingObj.authHeader.headingText}</strong>
                      </ListItemText>
                      <ListItemText sx={{ marginTop: 0 }}>
                        Version:{' '}
                        <strong>
                          {packageJson.version}
                          {buildNumber}, {unityVersion}
                        </strong>
                      </ListItemText>
                    </List>
                    <Copyright
                      align='left'
                      showVersion={false}
                      styles={{ color: 'secondary.main', marginTop: 0 }}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Box>
            </Grow>
          )}
        </Popper>
      </AppBar>
    </Box>
  );
};

export default Navigation;
