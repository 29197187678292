import { useTheme } from '@mui/material';
import MuiButton, { ButtonProps } from '@mui/material/Button';

interface Props extends ButtonProps {
  buttonStyle?: { [key: string]: any };
}

const Button: React.FC<Props> = ({
  children,
  buttonStyle,
  variant = 'contained',
  ...props
}: Props) => {
  const theme = useTheme();
  const baseStyle = {
    m: '0',
    p: '7px 42px',
    minWidth: '206px',
    borderRadius: '24px',
    letterSpacing: '0.52px',
  };

  const VARIANTS = {
    outlined: {
      ...theme.components.MuiButton.styleOverrides,
      ...baseStyle,
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    },
    contained: {
      ...theme.components.MuiButton.styleOverrides,
      ...baseStyle,
      color: '#292C3C',
      background: '#FFC331',
    },
  };
  return (
    <MuiButton
      style={
        variant === 'contained'
          ? { ...VARIANTS.contained }
          : { ...VARIANTS.outlined }
      }
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
