import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useRef, useState } from 'react';
import { browserName } from 'react-device-detect';
import { Unity, useUnityContext } from 'react-unity-webgl';
import * as api from '../../apis';
import {
  useAzureSttService,
  useGetMessageFromUnity,
  useRequestFullscreen,
  useSendMessageToUnity,
} from '../../hooks';
import { UnityMessages } from '../../types/enums/UnityMessages';

import { performanceLogger } from '../../helpers';

import * as Utils from '../../utils';
import DownloadBuildWrapper from './DownloadBuildWrapper';
import RxdDebugger from './RxdDebugger';
import './TSAWebGLPlayer.css';

// Import types.
import { StreamingPlayerStatus } from '../../types/StreamingPlayerStatus';

const rxd = (window as any).RXD;
interface WebGLPlayerProps extends WrappedWebGLPlayerProps {
  loaderUrl: string;
  dataUrl: string;
  frameworkUrl: string;
  codeUrl: string;
  setUnityLoadingProgress: (progress: number) => void;
}

const TSAWebGLPlayer = (props: WebGLPlayerProps) => {
  const {
    orgId,
    deepLinkGUID,
    debugPlayer,
    debugRxd,
    unityVersion,
    onPlayerStatus,
    fullscreenExternal,
    setFullscreenExternal,
    forceSendRXDEvents,
    stopPolling,
    startPolling,
    setUnityLoadingProgress,
  } = props;

  let webglCdn = process.env.REACT_APP_WEBGL_CDN + process.env.REACT_APP_UNITY_VERSION;
  if (unityVersion && webglCdn) {
    // eslint-disable-next-line no-useless-escape
    webglCdn = webglCdn.replace(/\/[^\/]*$/, `/${unityVersion}`);
  }

  const unityContext = useUnityContext({
    loaderUrl: props.loaderUrl,
    dataUrl: props.dataUrl,
    frameworkUrl: props.frameworkUrl,
    codeUrl: props.codeUrl,
    streamingAssetsUrl: `${webglCdn}/StreamingAssets`,
  });
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    requestFullscreen,
    unload,
    initialisationError,
  } = unityContext;
  const [sendMessageToUnity] = useSendMessageToUnity(unityContext, debugPlayer);
  const messageFromUnity = useGetMessageFromUnity(unityContext, debugPlayer);
  const [speechLanguage, setSpeechLanguage] = useState('en-US');
  const [readyMessage, setReadyMessage] = useState(false);
  const [playerError, setPlayerError] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [playerStarted, setPlayerStarted] = useState(false);
  const [playerStatus, setPlayerStatus] =
    useState<StreamingPlayerStatus>('loading');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFullScreen] = useRequestFullscreen(
    fullscreenExternal,
    setFullscreenExternal,
    debugPlayer,
  );
  const [
    speechRecognitionStarted,
    startAzureSpeechRecognition,
    stopAzureSpeechRecognition,
    recognizedText,
    recognizedTextSentToUnity,
    audioLevel,
  ] = useAzureSttService(
    speechLanguage,
    playerReady,
    playerStatus,
    sendMessageToUnity,
    startPolling,
    stopPolling,
    debugPlayer,
    browserName,
  );

  const startPlayer = useCallback(async () => {
    if (debugPlayer) console.log('TSA-WebGL: Call to start player!');
    setPlayerStarted(true);

    // Get the handoffToken (could be delayed slightly).
    setTimeout(async () => {
      if (rxd) stopPolling(); // Stop RXD Polling before making API call.
      await api.getHandoffToken();
      if (rxd) startPolling(); // Resume RXD Polling after the API call has finished.
    }, 500);
  }, [debugPlayer, startPolling, stopPolling]);

  const stopPlayer = useCallback(async () => {
    if (debugPlayer) console.log('TSA-WebGL: Call to stop player!');
    setPlayerStarted(false);
    setPlayerStatus('stopped');
    stopAzureSpeechRecognition();
    await unload();
  }, [debugPlayer, stopAzureSpeechRecognition, unload]);

  // This one will help determine when the Player is ready by detecting when the canvas element is added.
  // This is also not to be mixed with when the Unity player isLoaded, which is a different thing and happens later.
  const unityRef = useCallback(
    (node: { nodeName: string } | null) => {
      if (node !== null) {
        const isCanvas = node.nodeName?.toLowerCase() === 'canvas';
        if (!playerReady && isCanvas) {
          setPlayerReady(true);
          startPlayer();
          performanceLogger('canvas ready');
        }
      }
    },
    [playerReady, startPlayer],
  );

  useEffect(() => {
    if (isLoaded) setPlayerStatus('loaded');
  }, [isLoaded]);

  useEffect(() => {
    onPlayerStatus(playerStatus);
  }, [onPlayerStatus, playerStatus]);

  useEffect(() => {
    if (loadingProgression) {
      setUnityLoadingProgress(loadingProgression);
    }
  }, [loadingProgression, setUnityLoadingProgress]);

  useEffect(() => {
    if (initialisationError) setPlayerError(true);
  }, [initialisationError]);


  const sendOrgIdToUnity = useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      if (event) event.preventDefault();
      sessionStorage.setItem('orgId', orgId);
      sendMessageToUnity(UnityMessages.RESPONSE_ORG_ID_MESSAGE, {
        OrgId: orgId,
        Success: true,
        ErrorMessage: null,
      });
    },
    [orgId, sendMessageToUnity],
  );

  const sendSecurityTokenToUnity = useCallback(() => {
    const securityToken =
      sessionStorage.getItem('talespinRppHandoffToken') ?? '';
    sendMessageToUnity(UnityMessages.RESPONSE_HANDOFF_TOKEN_MESSAGE, {
      Token: securityToken,
      Success: true,
      ErrorMessage: null,
    });
  }, [sendMessageToUnity]);

  const sendDeepLinkGuidToUnity = useCallback(() => {
    const guid = deepLinkGUID
      ? deepLinkGUID
      : '00000000-0000-0000-0000-000000000000';
    sendMessageToUnity(UnityMessages.RESPONSE_DEEPLINK_GUID_MESSAGE, {
      Guid: guid,
      Success: true,
      ErrorMessage: null,
    });
  }, [deepLinkGUID, sendMessageToUnity]);

  const recordTimeStampAndSendToUnity = useCallback(() => {
    sendMessageToUnity(UnityMessages.RESPONSE_RECORD_TIME_STAMP_MESSAGE, {
      timeStamp: performance.now(),
    });
  }, [sendMessageToUnity]);

  // variable to reference the `mediaStream` from the navigator.mediaDevice method.
  const streamRef = useRef<MediaStream | null>(null);

  const sendMicrophonePermissionToUnity = useCallback(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mediaStream) => {
        // store the ref of mediaStream into our stream variable
        streamRef.current = mediaStream;
        sendMessageToUnity(
          UnityMessages.RESPONSE_MICROPHONE_PERMISSION_MESSAGE,
          {
            HasPermission: true,
            DontAskAgain: false, // Cannot be distinguished.
            SafariReload: false
          },
        );
      })
      .catch((error) => {
        const deniedPermission = new RegExp(/Permission denied/);
        const requestDenied = deniedPermission.test(error.toString());
        const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;

        sendMessageToUnity(
          UnityMessages.RESPONSE_MICROPHONE_PERMISSION_MESSAGE,
          {
            HasPermission: false,
            DontAskAgain: requestDenied,
            SafariReload: isSafari
          },
        );
      });
  }, [sendMessageToUnity]);

  useEffect(() => {
    return () => {
      // cleanup by stopping all stream tracks on component unmount
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const sendMicrophoneStatusToUnity = useCallback(() => {
    const micname = 'microphone' as PermissionName;
    navigator.permissions.query({ name: micname }).then((result) => {
      sendMessageToUnity(UnityMessages.RESPONSE_MICROPHONE_STATUS_MESSAGE, {
        state: result.state,
      });
    });
  }, [sendMessageToUnity]);

  const onResponseSpeechRecognizedMessage = useCallback(() => {
    // TODO: Do something...
  }, []);

  const onResponseUpdateMicrophoneInformationMessage = useCallback(() => {
    // TODO: Do something...
  }, []);

  const onStartProcessingVoiceInputMessage = useCallback(() => {
    if (!speechRecognitionStarted) {
      startAzureSpeechRecognition();
    }
  }, [speechRecognitionStarted, startAzureSpeechRecognition]);

  const onStopProcessingVoiceInputMessage = useCallback(() => {
    if (speechRecognitionStarted) {
      stopAzureSpeechRecognition();
    }
  }, [speechRecognitionStarted, stopAzureSpeechRecognition]);

  const onRequestUpdateSpeechLanguageMessage = useCallback(
    (customData: { LanguageCode: string | null }) => {
      const langFromMessage = customData.LanguageCode;
      if (langFromMessage && langFromMessage !== speechLanguage) {
        setSpeechLanguage(langFromMessage);

        setTimeout(() => {
          sendMessageToUnity(
            UnityMessages.RESPONSE_UPDATE_SPEECH_LANGUAGE_MESSAGE,
            {
              Success: true,
              ErrorMessage: null,
            },
          );
        }, 1000);
      }
    },
    [sendMessageToUnity, speechLanguage],
  );

  const onRequestPackageIntegrationSessionMessage = useCallback(() => {
    sendMessageToUnity(
      UnityMessages.RESPONSE_PACKAGE_INTEGRATION_SESSION_MESSAGE,
      {
        IsPackageIntegration: !!rxd,
        Success: true,
        ErrorMessage: null,
      },
    );
  }, [sendMessageToUnity]);

  const logUserOut = useCallback(() => {
    const logout = async () => {
      try {
        await stopPlayer();
      } catch (error) {
        // Unable to stop player (wasn't fully loaded?)
        console.log('Unable to stop Player:');
        console.log(error);
      }
      // Logout doesn't make sense for auth with 'settoptoken', so exclude it, but OK for everything else.
      if (!sessionStorage.getItem('isSettoptokenAuth')) {
        setTimeout(() => {
          Utils.logout();
        }, 1500);
      } else if (rxd) {
        // Case when RXD Packaged Integration window/iframe.
        // First, send any RXD events before logout and exit.
        await forceSendRXDEvents(false);
        setTimeout(() => {
          Utils.logout();
          rxd?.ConcedeControl();
          console.log('RXD: ConcedeControl() called');
        }, 1500);
      }
    };
    logout();
  }, [stopPlayer, forceSendRXDEvents]);

  useEffect(() => {
    if (messageFromUnity?.eventName === UnityMessages.READY_MESSAGE)
      setReadyMessage(true);

    // Process messages received from Unity.
    if (playerStatus === 'loaded') {
      switch (messageFromUnity?.eventName) {
        case UnityMessages.REQUEST_ORG_ID_MESSAGE:
          sendOrgIdToUnity();
          break;
        case UnityMessages.REQUEST_HANDOFF_TOKEN_MESSAGE:
          sendSecurityTokenToUnity();
          break;
        case UnityMessages.REQUEST_DEEPLINK_GUID_MESSAGE:
          sendDeepLinkGuidToUnity();
          break;
        case UnityMessages.REQUEST_MICROPHONE_PERMISSION_MESSAGE:
          sendMicrophonePermissionToUnity();
          break;
        case UnityMessages.REQUEST_MICROPHONE_STATUS_MESSAGE:
          sendMicrophoneStatusToUnity();
          break;
        case UnityMessages.REQUEST_START_PROCESSING_VOICE_INPUT_MESSAGE:
          onStartProcessingVoiceInputMessage();
          break;
        case UnityMessages.REQUEST_STOP_PROCESSING_VOICE_INPUT_MESSAGE:
          onStopProcessingVoiceInputMessage();
          break;
        case UnityMessages.RESPONSE_SPEECH_RECOGNIZED_MESSAGE:
          onResponseSpeechRecognizedMessage();
          break;
        case UnityMessages.RESPONSE_UPDATE_MICROPHONE_INFORMATION_MESSAGE:
          onResponseUpdateMicrophoneInformationMessage();
          break;
        case UnityMessages.REQUEST_UPDATE_SPEECH_LANGUAGE_MESSAGE:
          onRequestUpdateSpeechLanguageMessage(messageFromUnity?.customData);
          break;
        case UnityMessages.REQUEST_PACKAGE_INTEGRATION_SESSION_MESSAGE:
          onRequestPackageIntegrationSessionMessage();
          break;
        case UnityMessages.REQUEST_RECORD_TIME_STAMP_MESSAGE:
          recordTimeStampAndSendToUnity();
          break;
        case UnityMessages.REQUEST_LOGOUT_MESSAGE:
          logUserOut();
          break;
        default:
        // Do nothing.
      }
    }
  }, [
    messageFromUnity,
    onRequestPackageIntegrationSessionMessage,
    onRequestUpdateSpeechLanguageMessage,
    onResponseSpeechRecognizedMessage,
    onResponseUpdateMicrophoneInformationMessage,
    onStartProcessingVoiceInputMessage,
    onStopProcessingVoiceInputMessage,
    playerStatus,
    recordTimeStampAndSendToUnity,
    sendDeepLinkGuidToUnity,
    sendOrgIdToUnity,
    sendSecurityTokenToUnity,
    sendMicrophonePermissionToUnity,
    sendMicrophoneStatusToUnity,
    logUserOut,
  ]);

  function onDebugEnableButtonClick() {
    if (readyMessage)
      sendMessageToUnity(UnityMessages.REQUEST_SWITCH_DEBUG_MODE_MESSAGE, {
        Enabled: true,
      });
  }

  return (
    <Box className='animated-video-bg'>
        {playerError ? (
          <Alert
            severity='error'
            variant='filled'
            sx={{
              mb: '1.5rem',
              width: '550px',
              textAlign: 'center',
              margin: '180px auto',
            }}
          >
            <AlertTitle>
              The TSA-WebGL player cannot be loaded. Make sure the Unity player
              URLs are valid.
            </AlertTitle>
          </Alert>
        ) : (
          <>
            <div
              className={`ts-player-wrapper ${
                debugPlayer || debugRxd ? 'debug-player-mode-on' : ''
              }`}
            >
              <div
                id='ts-player-container'
                className={`${
                  playerStarted && isLoaded ? 'player-is-loaded' : ''
                }`}
              >
                <div id='tsa-webgl-unity-player-wrapper'>
                  <Unity
                    ref={unityRef}
                    className='tsa-webgl-unity-player'
                    unityProvider={unityProvider}
                    // style={{ width: 800, height: 600 }}
                  />
                </div>
              </div>

              {debugRxd && rxd && <RxdDebugger rxd={rxd} />}

              {debugPlayer && (
                <div className='debug-player-section'>
                  <div id='debug-options'>
                    <h2>Debug Options</h2>
                    <button onClick={() => requestFullscreen(true)}>
                      Fullscreen
                    </button>
                    <button onClick={() => requestFullscreen(false)}>
                      Exit Fullscreen
                    </button>
                    <button onClick={onDebugEnableButtonClick}>
                      Unity Debug
                    </button>
                    <button onClick={() => sendOrgIdToUnity()}>
                      Send orgId to Unity
                    </button>
                  </div>
                  <div className='testing-speech-reco'>
                    <>
                      <div>
                        <h3>
                          Currently selected Speech-To-Text method:{' '}
                          <em className='stt-method'>AzureSTT</em>
                        </h3>
                      </div>
                      {
                        // Case when SttMethod.Azure.
                        <div>
                          <button onClick={startAzureSpeechRecognition}>
                            Start SR
                          </button>
                          <button onClick={stopAzureSpeechRecognition}>
                            Stop SR
                          </button>
                          {speechRecognitionStarted ? (
                            <span
                              style={{ marginLeft: '10px', color: 'green' }}
                            >
                              SR Started
                            </span>
                          ) : (
                            <span style={{ marginLeft: '10px', color: 'red' }}>
                              SR Stopped
                            </span>
                          )}
                        </div>
                      }
                      <h3 className='recognized-text'>
                        Recognizing Text:{' '}
                        {recognizedText && <span>{recognizedText}</span>}
                        <br />
                        Final Text Sent To Unity:{' '}
                        {recognizedTextSentToUnity && (
                          <span className='final-text'>
                            {recognizedTextSentToUnity}
                          </span>
                        )}
                        <div style={{ marginTop: '10px' }}>
                          Audio Level: {<span>{audioLevel}</span>}
                        </div>
                      </h3>
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
    </Box>
  );
};

interface WrappedWebGLPlayerProps {
  orgId: string;
  deepLinkGUID: string;
  debugPlayer: boolean;
  debugRxd: boolean;
  unityVersion: string;
  onPlayerStatus: (status: StreamingPlayerStatus) => void;
  fullscreenExternal: boolean;
  setFullscreenExternal: (maxScreenExt: boolean) => void;
  forceSendRXDEvents: (startPollingAfter?: boolean) => Promise<void>;
  startPolling: () => void;
  stopPolling: () => void;
  systemConfig: { [index: string]: any };
}

const WrappedWebGLPlayer = (props: WrappedWebGLPlayerProps) => {
  return (
    <DownloadBuildWrapper
      unityVersion={props.unityVersion}
      systemConfig={props.systemConfig}
      renderPlayer={(
        loaderUrl,
        dataUrl,
        frameworkUrl,
        codeUrl,
        setUnityLoadingProgress,
      ) => (
        <TSAWebGLPlayer
          {...props}
          loaderUrl={loaderUrl}
          dataUrl={dataUrl}
          frameworkUrl={frameworkUrl}
          codeUrl={codeUrl}
          setUnityLoadingProgress={setUnityLoadingProgress}
        />
      )}
    />
  );
};

export default WrappedWebGLPlayer;
