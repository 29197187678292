import * as React from 'react';

function ErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 5.25L18 21.75'
        stroke={color}
        stroke-width='5'
        stroke-linecap='round'
      />
      <path
        d='M21.375 31.375C21.375 33.239 19.864 34.75 18 34.75C16.136 34.75 14.625 33.239 14.625 31.375C14.625 29.511 16.136 28 18 28C19.864 28 21.375 29.511 21.375 31.375Z'
        fill={color}
      />
    </svg>
  );
}

const MemoErrorIcon = React.memo(ErrorIcon);
export default MemoErrorIcon;
