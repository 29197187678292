import analytics from '../analytics/analytics';

let lastTimeStamp = 0

function eventCountTracker() {
  const eventCounts = {};

  return function(str) {
    eventCounts[str]
      ? eventCounts[str] += 1 
      : eventCounts[str] = 1; 
    
    return eventCounts[str]; // Return the current count for the string
  };
}

const trackEventCount = eventCountTracker();

const performanceLogger = (methodName: string, messageName?: string | null, message?: string | null) => {

  const currentTime = performance.now()
  // performance.now() returns the time in milliseconds since the start of the page load

  console.log({
    time: currentTime,
    sinceLastTime: currentTime - lastTimeStamp,
    methodName, 
    messageName, 
    message})

    analytics.track('performance Logger', {
        category: methodName,
        event: messageName,
        name: message || 'performance Logger',
        value: currentTime,
        count: trackEventCount(`${methodName} - ${messageName} - ${message}`),
  });

    lastTimeStamp = currentTime
}

export { performanceLogger }