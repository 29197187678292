export enum UnityMessages {
  REQUEST_PLAY = 'RequestPlayMessage',
  REQUEST_PAUSE = 'RequestPauseMessage',
  REQUEST_TIMELINE_UPDATE_MESSAGE = 'RequestTimelineUpdateMessage',
  REQUEST_CONV_INPUT_CHANGE = 'RequestConversationInputTypeChangeMessage',
  REQUEST_MOVE_CAMERA_MESSAGE = 'RequestMoveCameraMessage',
  REQUEST_3D_PLAYBACK = 'Request3DPlaybackMessage',
  RESPONSE_3D_PLAYBACK = 'Response3DPlaybackMessage',
  REQUEST_3D_PLAYBACK_RESTART = 'Request3DPlaybackRestartMessage',
  REQUEST_3D_PLAYBACK_STOP = 'Request3DPlaybackStopMessage',
  REQUEST_SWITCH_DEBUG_MODE_MESSAGE = 'RequestSwitchDebugModeMessage',
  SYNC_WITH_TIMELINE_MESSAGE = 'SyncTimelineTimeMessage',
  REQUEST_PERFORMANCE_EDITOR_MESSAGE = 'RequestPerformanceEditorMessage',
  REQUEST_JUMP_TO_TIME_MESSAGE = 'RequestJumpToTimeMessage',
  RESPONSE_TIMELINE_UPDATE = 'ResponseTimelineUpdateMessage',
  READY_MESSAGE = 'ReadyMessage',
  REQUEST_SKILLS_MESSAGE = 'RequestSkillsMessage',
  REQUEST_MICROPHONE_PERMISSION_MESSAGE = 'RequestMicrophonePermissionMessage',
  RESPONSE_MICROPHONE_PERMISSION_MESSAGE = 'ResponseMicrophonePermissionMessage',
  REQUEST_MICROPHONE_STATUS_MESSAGE = 'RequestMicrophoneStatusMessage',
  RESPONSE_MICROPHONE_STATUS_MESSAGE = 'ResponseMicrophoneStatusMessage',
  RESPONSE_UPDATE_MAX_SKILLS_SCORE = 'ResponseUpdateMaxSkillsScoreMessage',
  REQUEST_UPDATE_MAX_SKILLS_SCORE = 'RequestUpdateMaxSkillsScoreMessage',
  REQUEST_UPDATE_SCORING_MESSAGE = 'RequestUpdateScoringMessage',
  RESPONSE_UPDATE_SCORING_MESSAGE = 'ResponseUpdateScoringMessage',
  REQUEST_UPDATE_VARIABLE_MESSAGE = 'RequestUpdateVariablesMessage',
  RESPONSE_UPDATE_VARIABLE_MESSAGE = 'ResponseUpdateVariablesMessage',
  UPDATE_NODE_INFORMATION_MESSAGE = 'UpdateNodeInformationMessage',
  REQUEST_FINAL_SCORE_MESSAGE = 'RequestFinalScoreMessage',
  RESPONSE_FINAL_SCORE_MESSAGE = 'ResponseFinalScoreMessage',
  LOADING_COMPLETE_MESSAGE = 'LoadingCompleteMessage',
  RESPONSE_3D_PLAYBACK_RESTART_MESSAGE = 'Response3DPlaybackRestartMessage',
  RESPONSE_SKILLS_MESSAGE = 'ResponseSkillsMessage',
  REQUEST_RECORD_TIME_STAMP_MESSAGE = 'RequestRecordTimeStampMessage',
  RESPONSE_RECORD_TIME_STAMP_MESSAGE = 'ResponseRecordTimeStampMessage',
  // TSA-Web Specific Messages: https://github.com/talespin-vr/talespin-airframe-message-schema/wiki/TSA-Web-Messages
  RESPONSE_NO_LISTENERS_MESSAGE = 'ResponseNoListenersMessage',
  REQUEST_APP_KEY_NAME_MESSAGE = 'RequestAppKeyNameMessage',
  RESPONSE_APP_KEY_NAME_MESSAGE = 'ResponseAppKeyNameMessage',
  REQUEST_DEEPLINK_GUID_MESSAGE = 'RequestDeeplinkGuidMessage',
  RESPONSE_DEEPLINK_GUID_MESSAGE = 'ResponseDeeplinkGuidMessage',
  REQUEST_LESSON_ID_MESSAGE = 'RequestLessonIdMessage',
  RESPONSE_LESSON_ID_MESSAGE = 'ResponseLessonIdMessage',
  REQUEST_HANDOFF_TOKEN_MESSAGE = 'RequestHandoffTokenMessage',
  RESPONSE_HANDOFF_TOKEN_MESSAGE = 'ResponseHandoffTokenMessage',
  REQUEST_ORG_ID_MESSAGE = 'RequestOrgIdMessage',
  RESPONSE_ORG_ID_MESSAGE = 'ResponseOrgIdMessage',
  REQUEST_SPEECH_RECOGNIZED_MESSAGE = 'RequestSpeechRecognizedMessage',
  RESPONSE_SPEECH_RECOGNIZED_MESSAGE = 'ResponseSpeechRecognizedMessage',
  REQUEST_START_PROCESSING_VOICE_INPUT_MESSAGE = 'RequestStartProcessingVoiceInputMessage',
  RESPONSE_START_PROCESSING_VOICE_INPUT_MESSAGE = 'ResponseStartProcessingVoiceInputMessage',
  REQUEST_STOP_PROCESSING_VOICE_INPUT_MESSAGE = 'RequestStopProcessingVoiceInputMessage',
  RESPONSE_STOP_PROCESSING_VOICE_INPUT_MESSAGE = 'ResponseStopProcessingVoiceInputMessage',
  REQUEST_UPDATE_MICROPHONE_INFORMATION_MESSAGE = 'RequestUpdateMicrophoneInformationMessage',
  RESPONSE_UPDATE_MICROPHONE_INFORMATION_MESSAGE = 'ResponseUpdateMicrophoneInformationMessage',
  REQUEST_UPDATE_SPEECH_LANGUAGE_MESSAGE = 'RequestUpdateSpeechLanguageMessage',
  RESPONSE_UPDATE_SPEECH_LANGUAGE_MESSAGE = 'ResponseUpdateSpeechLanguageMessage',
  REQUEST_PACKAGE_INTEGRATION_SESSION_MESSAGE = 'RequestPackageIntegrationSessionMessage',
  RESPONSE_PACKAGE_INTEGRATION_SESSION_MESSAGE = 'ResponsePackageIntegrationSessionMessage',
  REQUEST_LOGOUT_MESSAGE = 'RequestLogoutMessage',
}
