import axios, { AxiosInstance } from 'axios';
import jwt_decode from 'jwt-decode';
import { getSubdomain, logout } from '../utils';

// Import types.
import { Creds } from 'talespin-bc-authenticator/dist/types/library/types/Authenticator';
import { JwtDecoded } from '../types/JwtDecoded';
import SpeechToTextData from '../types/SpeechToTextData';
import SttConfigEndpoint from '../types/SttConfigEndpoint';

export const serverBaseURL = `${process.env.REACT_APP_PROXY_URL}`;

let axiosInstance: AxiosInstance | null = null;

let systemConfig: { [index: string]: any } = {};
export const setSystemConfig = (sysConfig: { [index: string]: any }) => {
  systemConfig = JSON.parse(JSON.stringify(sysConfig)); // Make a deep copy.
};

let packetId = 0;

// Static feature flag for organizations that will use subdomains as part of user IDs. See https://talespin.atlassian.net/browse/TSW-310
const orgsUsingSubdomainForUserID = new Set(['csod', 'tsqa']);

declare global {
  interface Window {
    newrelic: any;
  }
}

export const getNextPacketId = () => {
  // Ensures packetIDs are not reused.
  // If a lower packetID is sent in session, Braincloud will reject as 403, packet received out of order
  packetId++;
  return packetId;
};

export let tsRppToken: string | null = null;
export const setTsRppToken = (token: string) => {
  tsRppToken = token || null;
};

export const initialize = (url: string, orgId: string) => {
  axiosInstance =
    axios.create({
      baseURL: url,
      headers: {
        'x-project': orgId,
        'Content-Type': 'application/json',
      },
    }) || null;
};

export const reset = () => {
  axiosInstance = null;
};

export const isReady = () => {
  return axiosInstance != null;
};

export const getAppSystemConfig = (
  orgId: string,
  requestingIp?: string | null,
) => {
  if (axiosInstance) {
    return axiosInstance.post('getAppSystemConfig', {
      project: orgId,
      requestingIP: requestingIp || '1.2.3.4',
    });
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const getStreamingConfig = () => {
  if (axiosInstance) {
    return axiosInstance.get('streamingTS/getStreamingConfiguration', {
      headers: {
        Authorization: `Bearer ${tsRppToken}`,
      },
    });
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const speechToTextApi = (buffer: ArrayBuffer, language: string) => {
  if (axiosInstance) {
    return axiosInstance.post('streamingTS/speechToText', buffer, {
      headers: {
        Authorization: `Bearer ${tsRppToken}`,
        'Content-Type': 'audio/wav',
        language,
      },
    });
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const getSecurityToken = () => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'createHandoffToken',
        scriptData: {},
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

let retryCount = 0;
const MAX_RETRIES = 3;

interface ApiResponseError {
  response: {
    data: {
      error: {
        message: string;
      };
    };
  };
}

const extractErrorReasonCode = (err: ApiResponseError) => {
  let message = err.response.data.error.message;
  let { reason_code } = JSON.parse(message);
  return reason_code;
};

const checkForUnexpectedPacketOrder = (err: ApiResponseError) => {
  const UNEXPECTED_PACKET_ORDER_REASON_CODE = 40566;
  if (extractErrorReasonCode(err) === UNEXPECTED_PACKET_ORDER_REASON_CODE) {
    window.newrelic.setCustomAttribute(
      'PACKET_OUT_OF_ORDER_COUNT',
      retryCount + 1,
    );
    return true;
  }
  return false;
};

// TODO: Move handofftoken api functionality to the Authenticator component.
export const getHandoffToken = async () => {
  console.log('Getting getHandoffToken...');

  try {
    const response = await getSecurityToken();

    sessionStorage.setItem(
      'talespinRppHandoffToken',
      response?.data?.data?.response?.securityToken,
    );

    console.log('Getting getHandoffToken... DONE!');
    return response?.data;
  } catch (err: any) {
    // Retry request in case server received requests out of order
    // Force the user to authenticate again if user session expired on the server.
    if (err.response?.status === 403) {
      if (checkForUnexpectedPacketOrder(err) && retryCount < MAX_RETRIES) {
        console.warn('Retrying getting getHandoffToken... !');
        getHandoffToken();
        retryCount++;
      } else {
        logout();
      }
    } else {
      console.error('err', err);
    }
    return null;
  }
};

export const createTSAShortPin = (deepLinkGUID?: string) => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'bh_cpd/createTSAShortPin',
        scriptData: {
          ...(deepLinkGUID && { deepLinkGUID }),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const getTSAShortPin = async (deepLinkGUID?: string) => {
  console.log('Getting getTSAShortPin...');
  try {
    const response = await createTSAShortPin(deepLinkGUID);

    console.log('Getting getTSAShortPin... DONE!');
    return response?.data;
  } catch (err: any) {
    // Retry request in case server received requests out of order
    // Force the user to authenticate again if user session expired on the server.
    if (err.response?.status === 403) {
      if (checkForUnexpectedPacketOrder(err) && retryCount < MAX_RETRIES) {
        console.warn('Retrying getting getTSAShortPin... !');
        getTSAShortPin(deepLinkGUID);
        retryCount++;
      } else {
        logout();
      }
    } else {
      console.error('err', err);
    }
    return null;
  }
};

export const getSpeechServiceConfig = () => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'speechtotext/getSpeechServiceConfiguration',
        scriptData: {},
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const getTextFromSpeech = async (
  buffer: ArrayBuffer,
  language: string,
): Promise<SpeechToTextData | null> => {
  try {
    const { data } = await speechToTextApi(buffer, language);
    return data;
  } catch (err: any) {
    console.error('err', err);
    return null;
  }
};

export const getAzureEndpoint = async () => {
  try {
    const data = await getSpeechServiceConfig();
    const speechRecoService =
      data?.data?.data?.response?.speechRecognitionService;
    const azureEndpoint: SttConfigEndpoint = speechRecoService?.endPoints?.find(
      (e: SttConfigEndpoint) => e.region === 'westus',
    );

    // Save the azureEndpoint to the cache.
    sessionStorage.setItem('azureEndpoint', JSON.stringify(azureEndpoint));

    return data;
  } catch (err: any) {
    // Retry request in case server received requests out of order
    // Force the user to authenticate again if user session expired on the server.
    if (err.response?.status === 403) {
      if (checkForUnexpectedPacketOrder(err) && retryCount < MAX_RETRIES) {
        console.warn('Retrying getting getAzureEndpoint... !');
        getAzureEndpoint();
        retryCount++;
      } else {
        logout();
      }
    } else {
      console.error('err', err);
    }
    return null;
  }
};

export const runS2SScript = (url: string, secret: string, data: any) => {
  return axios.post(url, data, {
    headers: {
      'x-bc-secret': secret,
    },
  });
};

export const getUserURLs = async (
  orgId: string,
  rxd: any,
  creds: Creds | null,
) => {
  if (!((rxd || (!rxd && creds)) && systemConfig)) return;

  try {
    const url = systemConfig?.additionalHooks?.s2s?.url;
    const secret = systemConfig?.additionalHooks?.s2s?.token;
    const lmsStudentName = systemConfig?.lmsStudentName;
    const userNamesArr = rxd?.GetStudentName().split(',');

    let data = {};

    if (rxd && !creds) {
      // User IS NOT authenticated.
      const urlParams = new URLSearchParams(window.location.search);
      const pipeUrl = urlParams.get('pipeurl') ?? '';
      const subdomain = getSubdomain(pipeUrl);
      const studentID = rxd.GetStudentID();
      const userID =
        subdomain.length && orgsUsingSubdomainForUserID.has(orgId)
          ? `${subdomain}-${studentID}`
          : studentID;
      console.info(
        '%cGetting Settoptoken...',
        'background:white; color:#292c3c; font-weight:600;',
      );
      data = {
        orgId: orgId,
        scriptName: 'bh_cpd/getUserURLs',
        scriptData: {
          userEmail: userID,
          contactEmail: studentID,
          userGivenName: lmsStudentName ? userNamesArr[1].trim() : '',
          userSurname: lmsStudentName ? userNamesArr[0].trim() : '',
        },
      };
    } else {
      if (creds) {
        // User IS authenticated.
        console.info(
          '%cMaking getUserURLs call...',
          'background:white; color:#292c3c; font-weight:600;',
        );
        const jwtDecoded: JwtDecoded = jwt_decode(creds?.jwt);
        data = {
          orgId: orgId,
          scriptName: 'bh_cpd/getUserURLs',
          scriptData: {
            profileId: jwtDecoded?.id,
          },
        };
      }
    }

    const response = await runS2SScript(url, secret, data);
    if (response?.data) {
      return response?.data;
    }
  } catch (error: any) {
    console.error(`Error getting getUserURLs ${JSON.stringify(error)}`);
    return Promise.reject(error);
  }
};

export const getUserHasPalData = () => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'runway/createUserPALLicense',
        scriptData: {},
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

// TODO: Move handofftoken api functionality to the Authenticator component.
export const userHasPal = async (creds: Creds | null) => {
  if (!creds && systemConfig) return;

  try {
    if (creds) {
      // User IS authenticated.
      console.info(
        '%cMaking userHasPal call...',
        'background:white; color:#292c3c; font-weight:600;',
      );
      const response = await getUserHasPalData();

      if (response?.data?.data?.response) {
        return response?.data?.data?.response;
      }
    }
  } catch (err: any) {
    // Force the user to authenticate again if user session expired on the server.

    if (err.response?.status === 403) {
      if (
        checkForUnexpectedPacketOrder(err) &&
        retryCount < MAX_RETRIES &&
        creds
      ) {
        console.warn('Retrying getting userHasPal... !');
        userHasPal(creds);
        retryCount++;
      } else {
        logout();
      }
    } else {
      console.error(`Error getting userHasPal ${JSON.stringify(err)}`);
    }
    return Promise.reject(err);
  }
};

export const getLessonURLMapping = async (
  orgId: string,
  deepLinkGUID: string,
) => {
  if (!(systemConfig && deepLinkGUID)) return;

  try {
    console.log('RXD: Making getLessonURLMapping call...');
    const url = systemConfig?.additionalHooks?.s2s?.url;
    const secret = systemConfig?.additionalHooks?.s2s?.token;
    const data = {
      orgId: orgId,
      scriptName: 'bh_cpd/getLessonURLMapping',
      scriptData: {
        deepLinkGUID: deepLinkGUID,
      },
    };

    const response = await runS2SScript(url, secret, data);
    if (response?.data) {
      return response?.data;
    }
  } catch (error: any) {
    console.error(`Error with getLessonURLMapping: ${JSON.stringify(error)}`);
    return Promise.reject(error);
  }
};

export const getUnsentRXDEvents = (appKeyName?: string, appId?: string) => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'runway/getUnsentRXDEvents',
        scriptData: {
          appId,
          appKeyName,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const updateUnsentRXDEvents = (entityIds: string[]) => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'runway/updateUnsentRXDEvents',
        scriptData: {
          entityIds,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};

export const getPackageIntegrationStatus = (
  appKeyNames?: string[],
  appIds?: string[],
) => {
  if (axiosInstance) {
    return axiosInstance.post(
      'script/run',
      {
        scriptName: 'runway/getPackageIntegrationStatus',
        scriptData: {
          appIds,
          appKeyNames,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tsRppToken}`,
          'x-packetid': getNextPacketId(),
        },
      },
    );
  } else {
    throw new Error('Axios has not been setup yet.');
  }
};
