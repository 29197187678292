import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useCallback, useEffect, useState } from 'react';
import { Authenticator } from 'talespin-bc-authenticator';
import { Creds } from 'talespin-bc-authenticator/dist/types/library/types/Authenticator';
import Main from './Main';
import * as api from './apis';
import * as Utils from './utils';
// import { isMobile, browserName } from 'react-device-detect';
// import UnsupportedBrowser from './components/UnsupportedBrowser';
// import MobileScreen from './components/MobileScreen';
import './App.css';
import BrandingObj from './assets/Branding';
import backgroundVideo from './assets/TSA_Background_720p.mp4';
import backgroundImg from './assets/TSA_fallback_bg_img.webp';
import Copyright from './components/Copyright';
import PoweredByTalespin from './components/Global/components/PoweredByTalespin';
import LoginMessagesPage from './components/Login/LoginMessagesPage';
import MemoTalespinHorizontalLogo from './components/svg-icons/TalespinHorizontalLogo';
import { performanceLogger } from './helpers';
import { UserUrls } from './types/UserUrls';

const rxd = (window as any).RXD;

const App = () => {
  const [apiReady, setApiReady] = useState<boolean>(false);
  const [orgId, setOrgId] = useState(Utils.getOrgId());
  const [systemConfig, setSystemConfig] = useState<any>(null);
  const [creds, setCreds] = useState<Creds | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [settoptoken, setSettoptoken] = useState('');
  const [userUrls, setUserUrls] = useState<UserUrls | null>(null);
  const [rxdPalLicensesError, setRxdPalLicensesError] = useState('');
  const [userHasPalError, setUserHasPalError] = useState('');

  // isDefaults=true means it is not a white-label app. But this flag can also be absent (the systemConfig model is very freeform which is why this looks odd)
  const isDefaults =
    !!!!systemConfig?.talespinStreamingPlayer?.whiteLabel?.isDefaults;

  // Init the API layer.
  useEffect(() => {
    api.reset();
    api.initialize(api.serverBaseURL, orgId);
    setApiReady(api.isReady());
  }, [orgId, setApiReady]);

  const getSystemConfig = useCallback(
    async (orgId: string) => {
      if (!apiReady) return;
      try {
        const response = await api.getAppSystemConfig(orgId);
        if (response.data) {
          api.setSystemConfig(response.data);
          setSystemConfig(response.data); // Set it in local state.
        }
      } catch (error: any) {
        console.error(
          `Error getting system configuration ${JSON.stringify(error)}`,
        );

        if (error.response?.status === 403) {
          console.error(`Project "${orgId}" is not available.`);
        } else {
          console.error('Error', error);
        }
      }
    },
    [apiReady],
  );

  useEffect(() => {
    const orgId = Utils.getOrgId();
    if (orgId) {
      setOrgId(orgId);
      getSystemConfig(orgId);
    }
  }, [getSystemConfig]);

  const onDataConsent = useCallback((dataConsent: any) => {
    console.log('Data-Consent is:', dataConsent);
  }, []);

  const onLogin = useCallback((creds: Creds) => {
    // Store user details for navbar display
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        displayName: creds.userData?.displayName,
        username: creds.userData?.externalId,
        email: creds.userData?.email,
      }),
    );

    setCreds(creds);

    const { jwt, userData, settoptoken } = creds;
    if (jwt) api.setTsRppToken(jwt);
    if (userData?.role) setUserRole(userData?.role);
    // TODO: replace sessionStorage for isSettoptokenAuth similar to userRole.
    if (settoptoken?.token && settoptoken?.isValid) {
      sessionStorage.setItem('isSettoptokenAuth', 'true');
    } else {
      sessionStorage.removeItem('isSettoptokenAuth');
    }

    performanceLogger('user is logging in');
  }, []);

  const onLogout = useCallback(() => {
    console.log('User is logged out.');

    setCreds(null);

    Utils.logout();
  }, []);

  // In the case of RXD it will get called twice, first time before the user is authenticated to get the settoptoken, then after the user has been authenticated it calls it again but this time passing profileId to get userUrls.
  const getUserURLs = useCallback(async () => {
    if (!(apiReady && systemConfig)) return;

    const data = await api.getUserURLs(orgId, rxd, creds);
    if (data) {
      if (data.errors) {
        setRxdPalLicensesError(data.errors[0]?.errorMessage);
      } else {
        setSettoptoken(data?.data?.settoptoken);
        setUserUrls(data?.data);
      }
    } else {
      if (data?.status === 'ERROR' && data?.techMessage) {
        console.error('Error', JSON.parse(data?.techMessage));
      } else {
        if (data) console.error('Error', JSON.parse(data));
      }
    }
  }, [apiReady, creds, orgId, systemConfig]);

  useEffect(() => {
    getUserURLs();
  }, [getUserURLs, creds]);

  const userHasPal = useCallback(async () => {
    if (!(apiReady && systemConfig)) return;

    const data = await api.userHasPal(creds);
    if (data) {
      if (!data.data?.hasPal && data.errors) {
        setUserHasPalError(data.errors[0]?.errorMessage);
      }
    } else {
      if (data?.status === 'ERROR' && data?.techMessage) {
        console.error('Error', JSON.parse(data?.techMessage));
      } else {
        if (data) console.error('Error', JSON.parse(data));
      }
    }
  }, [apiReady, creds, systemConfig]);

  useEffect(() => {
    if (userUrls) userHasPal();
  }, [userHasPal, creds, userUrls]);

  // if (isMobile) {
  //     return <MobileScreen />;
  // }

  // if (browserName === 'Firefox') {
  //     return <UnsupportedBrowser />;
  // }

  if (!orgId) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle='Error'
        messageText={`Organization ID is required to run the app! Please make sure an "orgId" is provided in the URL.`}
        transparentBackground={false}
      />
    );
  }

  // RXD only: Throw an error for when missing PAL.
  if (rxd && rxdPalLicensesError) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle='Authentication Error'
        messageText={rxdPalLicensesError}
        transparentBackground={false}
        exitButton={'Exit'}
      />
    );
  }

  // Throw an error for when the user has PAL.
  if (userHasPalError) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle=''
        messageText={userHasPalError}
        transparentBackground={false}
        exitButton={'Exit'}
      />
    );
  }

  const HeaderLogo = () => {
    if (systemConfig) {
      return !isDefaults &&
        systemConfig?.talespinStreamingPlayer?.whiteLabel?.images?.companyLogo2
          ?.src ? (
        <img
          src={
            systemConfig?.talespinStreamingPlayer?.whiteLabel?.images
              ?.companyLogo2?.src
          }
          height={45}
          style={{ marginBottom: '2rem' }}
          alt='Powered by Talespin'
        />
      ) : (
        <MemoTalespinHorizontalLogo
          color='white'
          style={{
            position: 'relative',
            top: -55,
          }}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
      className='animated-video-bg'
    >
      <video autoPlay loop muted playsInline className='animated-video'>
        <source src={backgroundVideo} type='video/mp4' />
      </video>
      <picture>
        <source src={backgroundImg} type='image/webp' />
        <img src={backgroundImg} alt='background' className='fallback-img' />
      </picture>
      {/* BEGIN: AUTHENTICATOR COMPONENT */}
      {!creds &&
        systemConfig && // Checking systemConfig here so that any API calls dependent on systemConfig execute before the auth component, eg. getting RXD related settoptoken.
        !(rxd && !settoptoken) && ( // Only when RXD make sure setoptoken is set.
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              top: !isDefaults ? -60 : 'inherit', // Take into account that white labeled logos might be taller than Talespin's, so anchor to the Authenticator instead of the entire Login component
            }}
          >
            <HeaderLogo />
            <Authenticator
              appName={'streaming'} // example options: 'passport', 'runway', or 'streaming'
              serverBasePath={api.serverBaseURL}
              orgId={orgId}
              branding={BrandingObj}
              settoptoken={settoptoken} // Optional, used when settoptoken is passed as a prop instead of a querystring param.
              dataConsentTriggerElementId={'data-consent-trigger'}
              onDataConsent={onDataConsent}
              onLogin={onLogin}
              onLogout={onLogout}
              enablePasswordReset={false}
              enableOrgIdChange={true}
              showCopyright={false}
            />
          </Box>
        )}
      {/* END: AUTHENTICATOR COMPONENT */}

      {creds &&
        ((creds?.tosRequired !== undefined && !creds?.tosRequired) ||
          (creds?.tosRequired && creds?.tosAccepted)) && (
          <Main
            creds={creds}
            userRole={userRole}
            systemConfig={systemConfig}
            isWhiteLabelApp={systemConfig?.projectAppId && !isDefaults}
            branding={BrandingObj}
            userUrls={userUrls}
          />
        )}

      {systemConfig?.projectAppId && !isDefaults && <PoweredByTalespin />}
      {systemConfig?.dataConsent?.streaming && !creds && (
        <div
          className='global-pp-tos-dc-links'
          style={{
            position: 'absolute',
            bottom: '20px',
            textAlign: 'center',
            color: '#696E84',
          }}
        >
          <Link
            style={{
              textDecoration: 'underline',
              letterSpacing: '0px',
              font: 'normal normal normal 12px/14px "Archivo Regular"',
              color: '#FFC100',
              cursor: 'pointer',
              margin: '8px',
            }}
            href={'https://www.talespin.com/privacy-policy'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </Link>
          {' | '}
          <Link
            style={{
              textDecoration: 'underline',
              letterSpacing: '0px',
              font: 'normal normal normal 12px/14px "Archivo Regular"',
              fontSize: '12px',
              fontFamily: 'Archivo Regular',
              color: '#FFC100',
              cursor: 'pointer',
              margin: '8px',
            }}
            href={'https://www.talespin.com/terms-of-use'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </Link>
          {' | '}
          <Link
            id='data-consent-trigger'
            style={{
              textDecoration: 'underline',
              letterSpacing: '0px',
              font: 'normal normal normal 12px/14px "Archivo Regular"',
              color: '#FFC100',
              cursor: 'pointer',
              margin: '8px',
            }}
          >
            Change Cookie Preferences
          </Link>
        </div>
      )}
      <div
        className='global-pp-tos-dc-links'
        style={{
          position: 'absolute',
          bottom: '20px',
          textAlign: 'center',
          color: '#696E84',
        }}
      >
        <Copyright />
      </div>
    </Box>
  );
};

export default App;
