import * as React from 'react';

function LessThan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      aria-hidden='true'
      width='1em'
      height='1em'
      {...props}
    >
      <path d='M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z' />
    </svg>
  );
}

const MemoLessThan = React.memo(LessThan);
export default MemoLessThan;
