import { Typography } from '@mui/material';
import MemoTalespinHorizontalLogo from '../../svg-icons/TalespinHorizontalLogo';

const PoweredByTalespin = () => (
  <div
    style={{
      position: 'absolute',
      bottom: 50,
    }}
  >
    <Typography align='center' fontSize='small' lineHeight={2}>
      Powered by:
    </Typography>
    <div
      style={{
        maxWidth: 126,
      }}
    ></div>
    <MemoTalespinHorizontalLogo color='white' width={126} />
  </div>
);

export default PoweredByTalespin;
