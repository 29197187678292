import { useEffect, useRef, useState } from 'react';

export const useAudioLevel = (speechRecognitionStarted: boolean) => {
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);
  const [audioLevel, setAudioLevel] = useState(0);

  const clearIntervalRef = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    let mediaStream: MediaStream,
      audioContext: AudioContext,
      scriptNode: ScriptProcessorNode;

    const processAudio = async () => {
      if (speechRecognitionStarted) {
        try {
          // create an AudioContext
          audioContext = new AudioContext();

          // create a MediaStreamSource from the user's microphone
          mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          const source = audioContext.createMediaStreamSource(mediaStream);

          // create a ScriptProcessorNode to process the audio data
          scriptNode = audioContext.createScriptProcessor(2048, 1, 1);
          let maxAudioLevel = -Infinity;
          scriptNode.onaudioprocess = function (event) {
            const inputBuffer = event.inputBuffer.getChannelData(0);
            let sum = 0;
            for (let i = 0; i < inputBuffer.length; i++) {
              sum += Math.abs(inputBuffer[i]);
            }
            const rms = Math.sqrt(sum / inputBuffer.length);
            const dbfs = 20 * Math.log10(rms);
            maxAudioLevel = Math.max(maxAudioLevel, dbfs);
          };

          // connect the audio nodes
          source.connect(scriptNode);
          scriptNode.connect(audioContext.destination);

          // poll for audio level every one second
          intervalIdRef.current = setInterval(() => {
            let currentAudioLevel = Math.max(0, (maxAudioLevel + 30) / 30);
            if (currentAudioLevel < 0.4) currentAudioLevel = 0; // Flatten any value lower than 0.4 to 0, this is done to help Unity client display the ripple wave properly.
            setAudioLevel(currentAudioLevel);
            maxAudioLevel = -Infinity;
            // console.log('Current audio level:', currentAudioLevel);
          }, 1000);
        } catch (error) {
          console.error(error);
        }
      } else {
        setAudioLevel(0);
      }
    };
    processAudio();

    return () => {
      clearIntervalRef();
      if (scriptNode) {
        scriptNode.disconnect();
      }
      if (mediaStream) {
        mediaStream.getTracks()[0].stop();
      }
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [speechRecognitionStarted]);

  return audioLevel;
};
