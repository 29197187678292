import * as React from 'react';

function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  const size = props.fontSize || '36';
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 17.25C21.7279 17.25 24.75 14.06 24.75 10.125C24.75 6.18997 21.7279 3 18 3C14.2721 3 11.25 6.18997 11.25 10.125C11.25 14.06 14.2721 17.25 18 17.25Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 33C30 27.6096 24.6217 23.25 18 23.25C11.3783 23.25 6 27.6096 6 33'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const MemoUserIcon = React.memo(UserIcon);
export default MemoUserIcon;
