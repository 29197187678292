import * as React from 'react';

function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || ''}
    >
      <path
        d='M10.5 13.5V10.875C10.5 6.53063 11.75 3 18 3C24.25 3 25.5 6.53063 25.5 10.875V13.5'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24 33H12C7.2 33 6 31.375 6 24.875V21.625C6 15.125 7.2 13.5 12 13.5H24C28.8 13.5 30 15.125 30 21.625V24.875C30 31.375 28.8 33 24 33Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.9957 22.5H24.0091'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9918 22.5H18.0052'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9918 22.5H12.0052'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const MemoLockIcon = React.memo(LockIcon);
export default MemoLockIcon;
