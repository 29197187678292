import { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '../apis';
import * as Speech from 'microsoft-cognitiveservices-speech-sdk';
import { UnityMessages } from '../types/enums/UnityMessages';
import { useAudioLevel } from './useAudioLevel';
import { useCountdown } from './useCountdown';

// Import types.
import { StreamingPlayerStatus } from '../types/StreamingPlayerStatus';

const rxd = (window as any).RXD;
const segmentationTimeout = 1000;
// const endSpeechTimeout = 1500;

export const useAzureSttService = (
  speechLanguage: string,
  playerReady: boolean,
  playerStatus: StreamingPlayerStatus,
  sendMessageToUnity: (messageName: UnityMessages, data?: any) => void,
  startPolling: () => void,
  stopPolling: () => void,
  debugPlayer: boolean,
  browserName: string,
) => {
  const [speechRecognitionStarted, setSpeechRecognitionStarted] =
    useState(false);
  const audioLevel = useAudioLevel(speechRecognitionStarted);
  const [recognizedText, setRecognizedText] = useState<string | null>(null);
  const recognizedTextArr = useRef<string[]>([]);
  const [recognizedTextSentToUnity, setRecognizedTextSentToUnity] =
    useState('');
  const [confidence, setConfidence] = useState(0);
  const recognizer = useRef<Speech.SpeechRecognizer | null>(null);
  const [
    startContinuousRecognitionAsyncError,
    setStartContinuousRecognitionAsyncError,
  ] = useState(false);
  const [startOfSpeechDetected, setStartOfSpeechDetected] = useState(false);

  useEffect(() => {
    if (startContinuousRecognitionAsyncError) {
      console.log(
        '\n%cTSA-WebGL: Force restarting continuous recognition because of an error\n',
        'background:purple; color:white; font-weight:600;',
      );
      stopAzureSpeechRecognition();
      // Give it a bit of delay before starting again.
      setTimeout(() => {
        startAzureSpeechRecognition();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startContinuousRecognitionAsyncError]);

  const handleTimerCallback = () => {
    if (playerStatus === 'loaded') {
      const finalTextToBeSentToUnity = recognizedTextArr.current.join(' ');
      sendMessageToUnity(UnityMessages.REQUEST_SPEECH_RECOGNIZED_MESSAGE, {
        Utterance: finalTextToBeSentToUnity,
        Confidence: confidence,
      });
      setRecognizedTextSentToUnity(finalTextToBeSentToUnity);
      recognizedTextArr.current = [];
    }
  };

  // useCountdown here is used to set timers to clock a pause in the speach before the recognized speach is send off to Unity for processing.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeLeft, { startTimer, resetTimer }, countdownTimerIsRunning] =
    useCountdown(1.0, handleTimerCallback); // Set the `targetTime` in seconds, and if you use decimal point make sure they increment by 2, eg. 1.2, 1.4, 1.6, ..., 2.4, ..., etc.

  const setupAzureSpeechRecognition = useCallback(async () => {
    console.log('TSA-WebGL: setupAzureSpeechRecognition() called');

    let azureEndpoint = sessionStorage.getItem('azureEndpoint');
    
    if (azureEndpoint === 'undefined' || azureEndpoint === null) {
      await api.getAzureEndpoint();
      azureEndpoint = sessionStorage.getItem('azureEndpoint');
    }
      
    if (typeof azureEndpoint !== 'undefined' && azureEndpoint) {
      const azureConfig = JSON.parse(azureEndpoint);
      const speechConfig = Speech.SpeechConfig.fromSubscription(
        azureConfig.apiKey || '',
        azureConfig.region || '',
      );
      speechConfig.outputFormat = 1;
      speechConfig.speechRecognitionLanguage = speechLanguage;
      let audioConfig = Speech.AudioConfig.fromDefaultMicrophoneInput();
      speechConfig.setProperty(
        Speech.PropertyId.Speech_SegmentationSilenceTimeoutMs,
        segmentationTimeout.toString(),
      );
      recognizer.current = new Speech.SpeechRecognizer(
        speechConfig,
        audioConfig,
      );
    }
  }, [speechLanguage]);

  useEffect(() => {
    if (
      startOfSpeechDetected &&
      playerStatus === 'loaded' &&
      !countdownTimerIsRunning
    ) {
      sendMessageToUnity(
        UnityMessages.REQUEST_UPDATE_MICROPHONE_INFORMATION_MESSAGE,
        {
          AudioLevel: audioLevel,
          DeviceName: null,
          IsSpeaking: true,
          IsSilent: false,
          IsWorking: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioLevel, startOfSpeechDetected, countdownTimerIsRunning]);

  useEffect(() => {
    if (playerReady) {
      const fetchData = async () => {
        // Get Azure's STT Service Configuration.
        if (rxd) stopPolling(); // Stop RXD Polling before making API call.
        await api.getAzureEndpoint();
        if (rxd) startPolling(); // Resume RXD Polling after the API call has finished.

        // Fix for Safari: force request microphone permission.
        if (browserName === 'Safari') {
          await navigator.mediaDevices.getUserMedia({ audio: true });
        }

        await setupAzureSpeechRecognition();
      };
      fetchData();
    }
  }, [
    browserName,
    playerReady,
    setupAzureSpeechRecognition,
    startPolling,
    stopPolling,
  ]);

  const onAzureSpeechSDKStarted = () => {
    if (debugPlayer)
      console.log('TSA-WebGL: starting continuous speech recognition...');

    if (playerStatus === 'loaded') {
      sendMessageToUnity(
        UnityMessages.RESPONSE_START_PROCESSING_VOICE_INPUT_MESSAGE,
        {
          Success: true,
          ErrorMessage: null,
        },
      );
    }
  };

  const onAzureSpeechSDKStartError = (error: string) => {
    console.log(
      '\n%c--------- onAzureSpeechSDKStartError --------- \n',
      'background:red; color:white; font-weight:600;',
      error,
    );
    if (recognizer.current) {
      setStartContinuousRecognitionAsyncError(true);
    }
  };

  const onAzureSpeechSessionStarted = (
    _sender: Speech.Recognizer,
    _event: Speech.SessionEventArgs,
  ) => {
    if (debugPlayer)
      console.log('TSA-WebGL: speech recognition session started...');
  };

  const onAzureSpeechStartDetected = (
    _sender: Speech.Recognizer,
    _event: Speech.RecognitionEventArgs,
  ) => {
    if (debugPlayer)
      console.log('TSA-WebGL: Azure start of speech detected...');

    setStartOfSpeechDetected(true);
    resetTimer();
  };

  const onAzureSpeechRecognizing = (
    _sender: Speech.Recognizer,
    event: Speech.SpeechRecognitionEventArgs,
  ) => {
    if (debugPlayer)
      console.log(`TSA-WebGL: RECOGNIZING: Text=${event.result.text}`);

    resetTimer();

    setRecognizedText(event.result.text);
    setRecognizedTextSentToUnity('');
  };

  const onAzureSpeechRecognized = (
    _sender: Speech.Recognizer,
    event: Speech.SpeechRecognitionEventArgs,
  ) => {
    if (event.result.reason === Speech.ResultReason.RecognizedSpeech) {
      const resultJSON = JSON.parse(event.result.json);
      const recognizedArr = resultJSON.NBest;
      const bestResult = recognizedArr.sort(
        (a: any, b: any) => b.Confidence - a.Confidence,
      )[0];
      const confidence: number = bestResult.Confidence
        ? bestResult.Confidence
        : 0;
      const recognizedText: string | null = bestResult.Lexical
        ? bestResult.Lexical
        : null;

      if (debugPlayer)
        console.log(
          `TSA-WebGL: RECOGNIZED: Confidence=${confidence} Text=${recognizedText}`,
        );

      setRecognizedText(recognizedText);
      if (recognizedText) recognizedTextArr.current.push(recognizedText);
      setConfidence(confidence);

      if (playerStatus === 'loaded') {
        if (debugPlayer)
          console.log(
            '\n%cTSA-WebGL: recognizedTextArr\n',
            'background:yellow; color:blue; font-weight:600;',
            recognizedTextArr.current,
          );

        startTimer();
      }
    } else if (event.result.reason === Speech.ResultReason.NoMatch) {
      if (debugPlayer)
        console.log('TSA-WebGL: NOMATCH: Speech could not be recognized.');
    }
  };

  const onAzureSpeechEndDetected = (
    _sender: Speech.Recognizer,
    _event: Speech.RecognitionEventArgs,
  ) => {
    if (debugPlayer) console.log('TSA-WebGL: Azure end of speech detected...');
    setStartOfSpeechDetected(false);
  };

  const onAzureSpeechCancelled = (
    _sender: Speech.Recognizer,
    event: Speech.SpeechRecognitionCanceledEventArgs,
  ) => {
    console.log(`CANCELED: Reason=${event.reason}`);

    if (event.reason === Speech.CancellationReason.Error) {
      if (debugPlayer)
        console.log(`TSA-WebGL: "CANCELED: ErrorCode=${event.errorCode}`);
      if (debugPlayer)
        console.log(`TSA-WebGL: "CANCELED: ErrorDetails=${event.errorDetails}`);
      if (debugPlayer)
        console.log(
          'TSA-WebGL: CANCELED: Did you update the subscription info?',
        );
    }

    if (recognizer.current) {
      recognizer.current.stopContinuousRecognitionAsync();
    }
  };

  const onAzureSpeechSessionStopped = (
    _sender: Speech.Recognizer,
    _event: Speech.SessionEventArgs,
  ) => {
    if (debugPlayer) console.log('\n    TSA-WebGL: Session stopped event.');
    setSpeechRecognitionStarted(false);
    if (recognizer.current) {
      recognizer.current.stopContinuousRecognitionAsync();
    }
  };

  const startAzureSpeechRecognition = () => {
    setRecognizedText(''); // Clear any displayed text from previous session.
    if (!recognizer.current) {
      setupAzureSpeechRecognition()
    }

    if (recognizer.current) {
      setSpeechRecognitionStarted(true);
      if (debugPlayer)
        console.log('TSA-WebGL: startAzureSpeechRecognition() called.');
      recognizer.current.startContinuousRecognitionAsync(
        onAzureSpeechSDKStarted,
        onAzureSpeechSDKStartError,
      );
      recognizer.current.sessionStarted = onAzureSpeechSessionStarted;
      recognizer.current.speechStartDetected = onAzureSpeechStartDetected;
      recognizer.current.recognizing = onAzureSpeechRecognizing;
      recognizer.current.recognized = onAzureSpeechRecognized;
      recognizer.current.speechEndDetected = onAzureSpeechEndDetected;
      recognizer.current.canceled = onAzureSpeechCancelled;
      recognizer.current.sessionStopped = onAzureSpeechSessionStopped;
    }
  };

  const stopAzureSpeechRecognition = () => {
    setSpeechRecognitionStarted(false);
    if (recognizer.current) {
      if (debugPlayer) console.log('TSA-WebGL: stopping SR.');
      recognizer.current.stopContinuousRecognitionAsync();

      setStartOfSpeechDetected(false);
      resetTimer();
    }

    if (playerStatus === 'loaded') {
      sendMessageToUnity(
        UnityMessages.RESPONSE_STOP_PROCESSING_VOICE_INPUT_MESSAGE,
        {
          Success: true,
          ErrorMessage: null,
        },
      );
    }
  };

  return [
    speechRecognitionStarted,
    startAzureSpeechRecognition,
    stopAzureSpeechRecognition,
    recognizedText,
    recognizedTextSentToUnity,
    audioLevel,
  ] as [boolean, () => void, () => void, string, string, number];
};
