import { useCallback, useEffect, useState } from 'react';
import * as api from '../apis';
import * as Utils from '../utils';

// Import types.
import { RxdLessonStatus } from '../types/RxdLessonStatus';

const rxd = (window as any).RXD;

export const useRxdPolling = (
  orgId: string,
  lessonURLMapping: { [index: string]: any } | null,
  pollingDelay: number | null,
) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingError, setPollingError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [delay, setDelay] = useState<number | null>(pollingDelay || 30000);

  const startPolling = useCallback(() => {
    const currentLessonStatus: RxdLessonStatus = rxd?.GetStatus();
    console.log(
      `RXD: Polling STARTED... Current lesson status '${currentLessonStatus}' (${new Date().toLocaleTimeString()})`,
    );
    setIsPolling(true);
  }, []);

  const stopPolling = useCallback(() => {
    const currentLessonStatus: RxdLessonStatus = rxd?.GetStatus();
    console.log(
      `RXD: Polling STOPPED... Current lesson status '${currentLessonStatus}' (${new Date().toLocaleTimeString()})`,
    );
    setIsPolling(false);
  }, []);

  const getUnsentRXDEvents = useCallback(async () => {
    if (!rxd) return;

    const appKeyName = lessonURLMapping?.appKeyName || '';
    const appId = lessonURLMapping?.appId || '';

    if (appKeyName || appId) {
      try {
        console.log(
          `RXD: Polling getUnsentRXDEvents... (${new Date().toLocaleTimeString()})`,
        );
        const response = await api.getUnsentRXDEvents(appKeyName, appId);
        if (response?.data?.data?.success && response?.data?.data?.response) {
          const count = response?.data?.data?.response?.data?.results?.count;
          const items = response?.data?.data?.response?.data?.results?.items;

          if (count > 0) {
            const entityIds: string[] = [];

            items.forEach((item: any) => {
              const eventType = item.data?.event?.type;
              const eventValue = item.data?.event?.value;

              switch (eventType) {
                case 'RecordFillInInteraction':
                  console.log(`RXD: "RecordFillInInteraction" called.`);
                  rxd?.RecordFillInInteraction(
                    eventValue?.id,
                    eventValue?.learnerResponse,
                    eventValue?.isCorrect,
                    eventValue?.correctResponse,
                    eventValue?.description,
                    eventValue?.weighting,
                    eventValue?.latency,
                    eventValue?.learningObjectiveId,
                  );
                  entityIds.push(item.entityId);
                  break;

                case 'SetPassed':
                  console.log('RXD: "SetReachedEnd" called.');
                  rxd?.SetReachedEnd();
                  console.log('RXD: "SetPassed" called.');
                  rxd?.SetPassed();
                  entityIds.push(item.entityId);
                  break;

                case 'SetFailed':
                  console.log('RXD: "SetFailed" called.');
                  rxd?.SetFailed();
                  entityIds.push(item.entityId);
                  break;

                case 'SetScore':
                  console.log(
                    `RXD: "SetScore(${eventValue?.score}, ${eventValue?.max}, ${eventValue?.min})" called.`,
                  );
                  rxd?.SetScore(
                    eventValue?.score,
                    eventValue?.max,
                    eventValue?.min,
                  );
                  entityIds.push(item.entityId);
                  break;

                // case 'ResetStatus':
                //     console.log('RXD: "ResetStatus" called.');
                //     rxd?.ResetStatus();
                //     entityIds.push(item.entityId);
                //     updatedStatus = 'unknown';
                //     break;

                default: // Do nothing.
              }
            });

            if (entityIds.length > 0) {
              console.log('RXD: Successfully updated MLS confirmed...!');

              // Send feedback to brainCloud.
              try {
                await api.updateUnsentRXDEvents(entityIds);
                console.log(
                  'RXD: Notify brainCloud to update status from unsent to sent for entityIds:',
                  entityIds,
                );
              } catch (error: any) {
                console.error(
                  `Error getting updateUnsentRXDEvents ${JSON.stringify(
                    error,
                  )}`,
                );
              }
            }
          }
        }
        console.log('RXD: Polling getUnsentRXDEvents... DONE');
      } catch (error: any) {
        if (
          parseInt(error.response?.status) === 403 ||
          parseInt(error.response?.status) === 401
        ) {
          setPollingError(true);
        }
        console.error(
          `Error getting getUnsentRXDEvents ${JSON.stringify(error)}`,
        );
        return null;
      }
    }
  }, [lessonURLMapping?.appId, lessonURLMapping?.appKeyName]);

  useEffect(() => {
    if (!stopPolling) {
      getUnsentRXDEvents();
    }
  }, [getUnsentRXDEvents, stopPolling]);

  /**
   * Send any RXD events immediately. Stops the currently running polling
   * and resumes it afterwords (optional).
   *
   * @param startPollingAfter boolean Whether to restart polling afterwords.
   */
  const forceSendRXDEvents = async (startPollingAfter?: boolean) => {
    stopPolling();
    await getUnsentRXDEvents();
    if (startPollingAfter) {
      startPolling();
    }
  };

  // Polling getUnsentRXDEvents() every 30 seconds or whatever the delay is set to.
  Utils.useInterval(
    async () => {
      await getUnsentRXDEvents();
    },
    isPolling ? delay : null, // Setting to null to "pause" the interval.
  );

  return [
    isPolling,
    startPolling,
    stopPolling,
    forceSendRXDEvents,
    pollingError,
  ] as [
    boolean,
    () => void,
    () => void,
    (startPollingAfter?: boolean) => Promise<void>,
    boolean,
  ];
};
