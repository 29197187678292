import * as React from 'react';

function BrowserIcon(props: React.SVGProps<SVGSVGElement>) {
  const size = props.fontSize || '96';
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      className={props.className || ''}
      width={size}
      height={size}
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 32.5636L88.9982 32.0239'
        stroke={color}
        stroke-width='3'
        stroke-miterlimit='10'
      />
      <circle cx='14.7818' cy='22.6059' r='3.5' fill={color} />
      <circle cx='25.6909' cy='22.6059' r='3.5' fill={color} />
      <circle cx='36.5999' cy='22.6059' r='3.5' fill={color} />
      <path
        d='M83.6 11H12.4C8.86538 11 6 13.8398 6 17.3429V78.6571C6 82.1602 8.86538 85 12.4 85H83.6C87.1346 85 90 82.1602 90 78.6571V17.3429C90 13.8398 87.1346 11 83.6 11Z'
        stroke={color}
        stroke-width='3'
        stroke-miterlimit='10'
      />
    </svg>
  );
}

export default BrowserIcon;
