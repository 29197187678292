import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import BrowserLinks from '../types/enums/BrowserLinks';
import ErrorModal from './Global/components/ErrorModal';

const BrowserOption = ({
  image,
  label,
  link,
}: {
  image: JSX.Element;
  label: string;
  link: string;
}) => {
  return (
    <Link
      href={link}
      target='_blank'
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {image}
      <Typography
        style={{
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '24px',
          letterSpacing: '0.3px',
          marginTop: '10px',
          textAlign: 'center',
          color: 'white',
        }}
      >
        {label}
      </Typography>
    </Link>
  );
};

const UnsupportedBrowser = () => {
  return (
    <ErrorModal errorTitle='Unsupported Browser'>
      <>
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'center',
            letterSpacing: '0.3px',
            marginBottom: '30px',
          }}
        >
          You’re trying to access Talespin App through a web browser we don’t
          support.
          <br />
          Please use the latest version of one of the following browsers:
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <BrowserOption
            image={
              <img
                alt='Microsoft Edge logo'
                src='/edge-logo@2x.png'
                width='70'
              />
            }
            label='Edge'
            link={BrowserLinks.Edge}
          />
          <BrowserOption
            image={
              <img
                alt='Google Chrome logo'
                src='/chrome-logo@2x.png'
                width='70'
              />
            }
            label='Chrome'
            link={BrowserLinks.Chrome}
          />
          <BrowserOption
            image={
              <img
                alt='Apple Safari logo'
                src='/safari-logo@2x.png'
                width='70'
              />
            }
            label='Safari'
            link={BrowserLinks.Safari}
          />
        </Box>
      </>
    </ErrorModal>
  );
};
const MemoUnsupportedBrowser = React.memo(UnsupportedBrowser);
export default MemoUnsupportedBrowser;
