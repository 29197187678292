import * as React from 'react';

function WarningIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.5151 4.25C11.8415 3.08333 10.1575 3.08334 9.48397 4.25L2.12276 17C1.44918 18.1667 2.29115 19.625 3.6383 19.625H18.3607C19.7079 19.625 20.5499 18.1667 19.8763 17L12.5151 4.25Z'
        stroke={color}
        strokeWidth='1.5'
        stroke-linejoin='round'
      />
      <path
        d='M11 7.5V13.1875'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M11 16.25V16.3375'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}

const MemoWarningIcon = React.memo(WarningIcon);
export default MemoWarningIcon;
