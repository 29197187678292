import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ModalityOption } from '../../types/ModalityOption';

interface Props {
  modalityOptions: string[];
  setSelectedModalityOption: (selectedModalityOption: ModalityOption) => void;
  modality: ModalityOption;
  heading: string;
  title: string;
  icon: any;
}

const ModalityButton = ({
  modalityOptions,
  setSelectedModalityOption,
  modality,
  heading,
  title,
  icon: IconComponent,
}: Props) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Tooltip
        title={`${
          !modalityOptions.includes(modality)
            ? 'Contact your administrator to enable this platform for your organization.'
            : ''
        }`}
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              lineHeight: '20px',
              backgroundColor: '#c0c0c0',
              color: 'black',
              fontSize: '12px',
            },
            '& .MuiTooltip-arrow': {
              color: '#c0c0c0',
            },
          },
        }}
      >
        <Card
          style={{
            borderRadius: 18,
            position: 'relative',
            width: 184,
          }}
        >
          <CardActionArea
            disabled={!modalityOptions.includes(modality)}
            onClick={() => setSelectedModalityOption(modality)}
            sx={{
              color: '#FFFFFF',
              background: 'rgba(16, 18, 31, 1)',
              '& .modality-icon circle': {
                fill: 'white',
              },
              '&:hover': {
                backgroundColor: 'rgba(16, 18, 31, 1)',
                color: '#FFC440',
                // Override MUI's default onHover overlay effect
                '& .MuiCardActionArea-focusHighlight': {
                  backgroundColor: 'transparent',
                  opacity: 0,
                },
                '& .modality-icon path': {
                  stroke: '#FFC440',
                },
                '& .modality-icon circle': {
                  fill: '#FFC440',
                },
              },
              '&.Mui-disabled': {
                background: 'black',
                opacity: '0.1',
              },
            }}
          >
            <CardContent
              style={{
                height: 184,
                width: 184,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '40px 16px 24px 16px',
              }}
            >
              <div style={{ textAlign: 'center', margin: '0.3rem auto 1rem' }}>
                <IconComponent
                  fontSize={50}
                  color='white'
                  className='modality-icon'
                />
              </div>
              <Typography
                sx={{
                  textAlign: 'center',
                  letterSpacing: 0,
                  fontFamily: 'Roboto SemiBold',
                  fontSize: '20px',
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  letterSpacing: 0,
                  fontFamily: 'Roboto Regular',
                  fontSize: '14px',
                }}
              >
                {heading}
              </Typography>
            </CardContent>
          </CardActionArea>
          {!modalityOptions.includes(modality) && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '0',
                color: '#FFC440',
                textAlign: 'center',
                bottom: '50%',
                right: '0',
                lineHeight: '10px',
                fontSize: '18px',
                fontFamily: 'Roboto SemiBold',
              }}
            >
              Not Enabled
            </div>
          )}
        </Card>
      </Tooltip>
    </Grid>
  );
};

export const MemoModalityButton = React.memo(ModalityButton);
