import { UnityContextHook } from 'react-unity-webgl/distribution/types/unity-context-hook';
import { performanceLogger } from '../helpers';
import { UnityMessages } from '../types/enums/UnityMessages';

export const useSendMessageToUnity = (unityContext?: UnityContextHook, debugPlayer?: boolean) => {

    const sendMessageToUnity = (messageName: UnityMessages, data?: any) => {
        performanceLogger('useSendMessageToUnity', messageName)
        if (debugPlayer)
            console.info(
                `\n%c ReactToUnity: ${messageName} `,
                'background:orange; color:blue; font-weight:600;',
                data && JSON.parse(JSON.stringify(data)),
            );
        if (unityContext?.sendMessage) {
            unityContext.sendMessage(
                '[BRIDGE]',
                'OnMessageReceived',
                JSON.stringify({
                    EventName: messageName,
                    customData: JSON.stringify(data || {}),
                }),
            );
        }
    };

    return [sendMessageToUnity] as [(messageName: UnityMessages, data?: any) => void];
};
