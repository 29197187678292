import * as React from 'react';

function DownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 1L7 7L1 1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const MemoDownArrowIcon = React.memo(DownArrowIcon);
export default MemoDownArrowIcon;
