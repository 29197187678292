import { useEffect, useMemo, useState } from 'react';
import * as api from '../apis';
import { logout } from '../utils';

// Import types.
import { renderMode } from '../types/renderMode';

export const useInitializeParams = () => {
  const deploymentType = useMemo(() => {
    const depType = window.location.hostname.split('.')[0];
    return depType
      ? depType.toLocaleLowerCase() === 'streamingplayer'
        ? 'PROD'
        : depType.toUpperCase()
      : 'DEV';
  }, []);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const [params, setParams] = useState({
    areQuerystringParamsRead: false,
    hasContentId: urlParams.has('contentId'),
    streamingConfigIndexId: '',
    isValidStreamingConfigIndexId: true,
    contentTitle: '',
    orgId: '',
    doesEnvironmentMatch: true,
    hasTrainingId: urlParams.has('trainingId'),
    trainingId: '',
    hasLessonId: urlParams.has('lessonId'),
    lessonId: '',
    langCode: 'en-US',
    hasDeepLinkGUID: urlParams.has('deepLinkGUID'),
    deepLinkGUID: '',
    debugApp: false,
    debugPlayer: false,
    debugRxd: false,
    renderMode: null as renderMode | null,
    unityVersion: '',
  });

  useEffect(() => {
    const getKeys = async () => {
      try {
        const { data } = await api.getStreamingConfig();

        // Organization ID.
        const orgId =
          sessionStorage.getItem('orgId') || urlParams.get('orgId') || '';
        const subdomain = window.location.hostname.split('.')[0];
        const prodSubdomain =
          process.env.REACT_APP_PROD_HOSTNAME?.split('.')[0];

        // App/Player ID.
        const contentIdParam = urlParams.get('contentId');
        const streamingConfigIndexId = contentIdParam
          ? (contentIdParam + orgId).toLocaleLowerCase()
          : '';
        let contentTitle = '';
        let doesEnvironmentMatch = true;
        let streamingEnvironment = '';
        let isValidStreamingConfigIndexId = true;
        if (
          typeof data === 'object' &&
          data !== null &&
          streamingConfigIndexId in data
        ) {
          contentTitle = data[streamingConfigIndexId].title;
          streamingEnvironment =
            data[streamingConfigIndexId].environment || 'prod'; // If `environment` missing, assume `prod`.
          doesEnvironmentMatch =
            streamingEnvironment === 'prod'
              ? prodSubdomain === subdomain
              : streamingEnvironment === subdomain;
        } else {
          isValidStreamingConfigIndexId = false;
        }

        // Module Name:
        const trainingId = urlParams.get('trainingId') || '';

        // Lesson ID:
        const lessonId = urlParams.get('lessonId') || '';

        // Language/Locale:
        const langCode = urlParams.get('langCode') || 'en-US';

        // GUID to Deep Linked Entity
        const deepLinkGUID = urlParams.get('deepLinkGUID') || '';

        // Debug App toggle.
        let debugApp =
          process.env.REACT_APP_DEBUG?.toLowerCase() === 'true' ? true : false;
        const debugAppParam = urlParams.get('debugApp');
        if (debugAppParam) {
          debugApp = debugAppParam.toLowerCase() === 'true' ? true : false;
        }

        // Debug Player toggle.
        let debugPlayer =
          process.env.REACT_APP_DEBUG_PLAYER?.toLowerCase() === 'true'
            ? true
            : false;
        const debugPlayerParam = urlParams.get('debugPlayer');
        if (debugPlayerParam) {
          debugPlayer =
            debugPlayerParam.toLowerCase() === 'true' ? true : false;
        }

        // RXD Debugger toggle.
        let debugRxd = false;
        const debugRxdParam = urlParams.get('debugRxd');
        if (debugRxdParam) {
          debugRxd = debugRxdParam.toLowerCase() === 'true' ? true : false;
        }

        // TSA-WebGL Player toggle (Will override the `tsaWebGLEnabled` feature flag).
        let renderMode: renderMode | null = null;
        const renderModeParam = urlParams.get('renderMode');
        if (renderModeParam) {
          renderMode = renderModeParam.toLowerCase() as renderMode;
        }

        // Querystring configurable unity version:
        const unityVersion = urlParams.get('unityVersion') || '';

        const areQuerystringParamsRead = true;

        setParams((prevParams) => ({
          ...prevParams,
          areQuerystringParamsRead,
          streamingConfigIndexId,
          isValidStreamingConfigIndexId,
          contentTitle,
          orgId,
          doesEnvironmentMatch,
          trainingId,
          lessonId,
          langCode,
          deepLinkGUID,
          debugApp,
          debugPlayer,
          debugRxd,
          renderMode,
          unityVersion,
        }));
      } catch (error: any) {
        console.log('error', error);
        logout();
      }
    };
    getKeys();
  }, [deploymentType, urlParams]);

  return params;
};
