import Box from '@mui/material/Box';
import jwt_decode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import analytics from './analytics/analytics';
import * as api from './apis';
import ErrorModal from './components/Global/components/ErrorModal';
import LandingPage from './components/LandingPage';
import LoginMessagesPage from './components/Login/LoginMessagesPage';
import OneTimePassword from './components/Pin/OneTimePassword';
import Navigation from './components/Player/Navigation';
import WrappedWebGLPlayer from './components/Player/TSAWebGLPlayer';
import MemoUnsupportedBrowser from './components/UnsupportedBrowser';
import DexieProvider from './context/dexie.context';
import { useInitializeParams } from './hooks/useInitializeParams';
import { useRxdPolling } from './hooks/useRxdPolling';
import * as Utils from './utils';
import { isGuid } from './utils';
// import MobileScreen from './components/MobileScreen';

// Import types.
import { Creds } from 'talespin-bc-authenticator/dist/types/library/types/Authenticator';
import { JwtDecoded } from './types/JwtDecoded';
import { ModalityOption } from './types/ModalityOption';
import { StreamingPlayerStatus } from './types/StreamingPlayerStatus';
import { UserUrls } from './types/UserUrls';

// Images.

const rxd = (window as any).RXD;

interface Props {
  creds: Creds;
  userRole: string | null;
  systemConfig: { [index: string]: any };
  isWhiteLabelApp: boolean;
  branding: { [index: string]: any };
  userUrls: UserUrls | null;
}

const Main = (props: Props) => {
  const {
    areQuerystringParamsRead,
    hasContentId,
    contentTitle,
    orgId,
    doesEnvironmentMatch,
    hasTrainingId,
    hasLessonId,
    lessonId,
    hasDeepLinkGUID,
    deepLinkGUID,
    debugPlayer,
    debugRxd,
    renderMode,
    unityVersion,
  } = useInitializeParams();
  const [playerStatus, setPlayerStatus] =
    useState<StreamingPlayerStatus>('loading');
  const [fullscreenExternal, setFullscreenExternal] = useState(false);
  const [modalityOptions, setModalityOptions] = useState<
    ModalityOption[] | null
  >(null);
  const [gettingModalityOptions, setGettingModalityOptions] =
    useState(!!hasDeepLinkGUID); // Set to true only if hasDeepLinkGUID, otherwise false;
  const [selectedModalityOption, setSelectedModalityOption] =
    useState<ModalityOption | null>(null);
  const [lessonURLMapping, setLessonURLMapping] = useState<{
    [index: string]: any;
  } | null>(null);
  const [
    deepLinkedTrainingModuleIsNoLongerAvailableMessage,
    setDeepLinkedTrainingModuleIsNoLongerAvailableMessage,
  ] = useState('');
  const pollingFrequency =
    props?.systemConfig?.talespinStreamingPlayer?.pollingFrequency * 1000 ||
    30000;
  const [
    isPolling,
    startPolling,
    stopPolling,
    forceSendRXDEvents,
    pollingError,
  ] = useRxdPolling(orgId, lessonURLMapping, pollingFrequency);
  const tsaWebGLEnabled =
    props?.creds?.orgConfig?.data?.config?.tsaWebGLEnabled;
  const isWebGL =
    renderMode === null ? !!tsaWebGLEnabled : renderMode === 'webgl'; // `renderMode` querystring parameter will override the `tsaWebGLEnabled` feature flag.
  const whiteLabelLogo =
    props.systemConfig?.talespinStreamingPlayer?.whiteLabel?.images
      ?.companyLogo2?.src;

  useEffect(() => {
    // If WebGL is not enabled or if only VR is enabled then immediately present user to OTP page for VR.
    // If no modalityOptions are set, default to WebGL.
    if (
      !isWebGL ||
      (modalityOptions?.length === 1 && modalityOptions?.includes('vr'))
    ) {
      setSelectedModalityOption('vr');
    } else if (
      isWebGL &&
      modalityOptions?.length === 1 &&
      modalityOptions?.includes('browser')
    ) {
      setSelectedModalityOption('browser');
    }
  }, [isWebGL, modalityOptions]);

  // RXD only code to be run just before window/tab is closed, so any unsaved progress in training can be properly saved on the LMS side.
  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      // Code to be executed before window closes.
      rxd?.ConcedeControl();
      console.log('RXD: ConcedeControl() called');
    }

    if (rxd) {
      const iframeWindow = (
        window.frameElement as HTMLIFrameElement | undefined
      )?.contentWindow;
      if (iframeWindow) {
        iframeWindow.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          iframeWindow.removeEventListener('beforeunload', handleBeforeUnload);
        };
      } else {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }
    }
  }, []);

  useEffect(() => {
    /* Track a page view */
    analytics.page();
    if (props?.creds) {
      // prevent tracking user before orgId is set
      if (orgId === '') {
        return;
      }

      const jwt = props?.creds?.jwt;
      const jwtDecoded: JwtDecoded = jwt_decode(jwt);

      /* Analytics: identify a visitor */
      analytics.identify(`${props?.creds?.userData?.externalId}`, {
        sessionId: jwtDecoded?.sessionId,
        organizationId: orgId,
      });

      // Analytics: 'User Login' event tracking.
      analytics.track('User Login', {
        category: 'Login',
        name: 'User Login',
        sessionId: jwtDecoded?.sessionId,
      });
    }
  }, [orgId, props?.creds]);

  const getLessonURLMapping = useCallback(async () => {
    if (!Utils.isGuid(deepLinkGUID)) return;

    try {
      const data = await api.getLessonURLMapping(orgId, deepLinkGUID);
      if (data?.success && data?.data) {
        setLessonURLMapping(data?.data);
      } else {
        if (data?.status === 'ERROR' && data?.techMessage) {
          console.error('Error with getLessonURLMapping: ', data?.techMessage);
        } else if (data?.errors) {
          console.error(
            'Error with getLessonURLMapping: ',
            data?.errors[0].errorMessage,
          );
          setDeepLinkedTrainingModuleIsNoLongerAvailableMessage(
            data?.errors[0].errorMessage,
          );
        } else {
          console.error('Error with getLessonURLMapping: ', JSON.parse(data));
        }
      }
    } catch (error: any) {
      console.error(`Error with getLessonURLMapping ${JSON.stringify(error)}`);
    }
  }, [deepLinkGUID, orgId]);

  useEffect(() => {
    getLessonURLMapping();
  }, [getLessonURLMapping]);

  const onPlayerStatus = useCallback((status: StreamingPlayerStatus) => {
    setPlayerStatus(status);
  }, []);

  // Determine the available "modality" options (for a given Learner) derived from the deepLinkGUID.
  const getModalityOptions = useCallback(async () => {
    let deliveryOptions: ModalityOption | ModalityOption[] | null = null;

    if (lessonURLMapping) {
      // First get delivery option(s) from lessonURLMapping.
      deliveryOptions = lessonURLMapping?.deliveryOption;

      // If its not there... get it via getPackageIntegrationStatus.
      if (!deliveryOptions) {
        const appKeyNames: string[] = lessonURLMapping?.appKeyName
          ? [lessonURLMapping?.appKeyName]
          : [];
        const appIds: string[] = lessonURLMapping?.appId
          ? [lessonURLMapping?.appId]
          : [];

        if (appKeyNames || appIds) {
          try {
            if (rxd && isPolling) stopPolling(); // Stop RXD Polling before making API call.
            console.log('RXD: Making getPackageIntegrationStatus call...');
            const response = await api.getPackageIntegrationStatus(
              appKeyNames,
              appIds,
            );
            if (
              response?.data?.data?.success &&
              Array.isArray(response?.data?.data?.response?.data) &&
              response?.data?.data?.response?.data.length
            ) {
              deliveryOptions =
                response?.data?.data?.response?.data[0]?.deliveryOption;
            }
          } catch (error: any) {
            console.error(
              `Error on getPackageIntegrationStatus ${JSON.stringify(error)}`,
            );
          } finally {
            if (rxd && !isPolling) startPolling(); // Resume RXD Polling after the API call has finished.
          }
        }
      }

      if (deliveryOptions) {
        if (Array.isArray(deliveryOptions)) {
          const hasBrowserDeliveryOption: boolean | undefined =
            deliveryOptions?.includes('browser');
          // To avoid re-render and simply continue with the loading of the streaming player do not set modalityOptions below if the only option is 'browser' (the default).
          if (!(deliveryOptions.length === 1 && hasBrowserDeliveryOption)) {
            setModalityOptions(deliveryOptions);
          }
        } else {
          if (deliveryOptions !== 'browser') {
            setModalityOptions([deliveryOptions]); // When deliveryOptions comes in as a string convert it to array.
          }
        }
      }
    }

    setGettingModalityOptions(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonURLMapping?.appId, lessonURLMapping?.appKeyName]);

  useEffect(() => {
    if (lessonURLMapping) {
      getModalityOptions();
    }
  }, [getModalityOptions, lessonURLMapping]);

  const onModalityOptionSelection = (
    selectedModalityOption: ModalityOption | null,
  ) => {
    setSelectedModalityOption(selectedModalityOption);
  };

  const showWhiteLabelLogo = () => {
    return (
      whiteLabelLogo && (
        <img
          src={whiteLabelLogo}
          height={45}
          style={{
            position: 'absolute',
            top: 125,
            left: 0,
            right: 0,
            margin: 'auto',
          }}
          alt='White label logo'
        />
      )
    );
  };

  if (deepLinkedTrainingModuleIsNoLongerAvailableMessage) {
    const msgs = deepLinkedTrainingModuleIsNoLongerAvailableMessage.split('.');
    if (Array.isArray(msgs) && msgs.length === 1) {
      msgs[0] = 'The requested content is no longer available.';
      msgs[1] =
        'Please contact your administrator to ensure you have access to the correct content.';
    }
    if (Array.isArray(msgs) && msgs.length > 1) {
      return (
        <div
          style={{
            flex: '1 1 auto',
            overflow: 'hidden',
            position: 'absolute',
            width: '100vw',
            height: '100vh',
          }}
        >
          <ErrorModal
            errorTitle={msgs[0].trim()}
            errorMessage={msgs[1].trim()}
          />
        </div>
      );
    }
  }

  if (areQuerystringParamsRead && !isWebGL) {
    // Covers the case when "contentId" is not provided.
    if (!hasContentId) {
      return (
        <LoginMessagesPage
          messageType='error'
          messageTitle='Error'
          messageText={`Content ID is required to run the app! Please make sure a valid "contentId" is provided in the URL.`}
        />
      );
    }

    // Make sure the domain matches the environment set in streamingConfig for this player's id.
    if (!doesEnvironmentMatch) {
      return (
        <LoginMessagesPage
          messageType='error'
          messageTitle='Error'
          messageText={`The provided domain doesn't match the streaming environment.`}
        />
      );
    }
  }

  // RXD only: Allow the user to authenticate again if user session expired on the server or any other polling related error.
  if (rxd && pollingError) {
    return (
      <LoginMessagesPage
        messageType='connectivity-issue'
        messageTitle='Connectivity problem detected'
        messageText={`Please close this session and restart.`}
        exitButton={'Exit Course'}
      />
    );
  }

  if (!hasTrainingId && hasLessonId) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle='Error'
        messageText={`Need to specify a trainingId in the URL if the lessonId is specified.`}
      />
    );
  }

  if (hasLessonId && !isGuid(lessonId)) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle='Error'
        messageText={`lesssonId specified in URL is not a valid GUID.`}
      />
    );
  }

  if (hasDeepLinkGUID && deepLinkGUID && !isGuid(deepLinkGUID)) {
    return (
      <LoginMessagesPage
        messageType='error'
        messageTitle='Error'
        messageText={`deepLinkGUID specified in URL is not a valid GUID.`}
      />
    );
  }

  if (modalityOptions && !selectedModalityOption) {
    return (
      <>
        <Navigation
          contentTitle={contentTitle}
          orgId={orgId}
          userRole={props?.userRole}
          forceSendRXDEvents={forceSendRXDEvents}
          systemConfig={props?.systemConfig}
          userUrls={props?.userUrls}
          showPlayerControls={false}
        />
        {props.isWhiteLabelApp && showWhiteLabelLogo()}
        <LandingPage
          contentTitle={contentTitle}
          modalityOptions={modalityOptions}
          onModalityOptionSelection={onModalityOptionSelection}
          systemConfig={props?.systemConfig}
        />
      </>
    );
  }

  if (areQuerystringParamsRead && selectedModalityOption === 'vr') {
    return (
      <>
        <Navigation
          contentTitle={contentTitle}
          orgId={orgId}
          userRole={props?.userRole}
          forceSendRXDEvents={forceSendRXDEvents}
          systemConfig={props?.systemConfig}
          userUrls={props?.userUrls}
          showPlayerControls={false}
        />
        {props.isWhiteLabelApp && showWhiteLabelLogo()}
        <OneTimePassword
          contentTitle={contentTitle}
          modalityOptions={modalityOptions}
          onModalityOptionSelection={onModalityOptionSelection}
          startPolling={startPolling}
          stopPolling={stopPolling}
          deepLinkGUID={deepLinkGUID}
          systemConfig={props?.systemConfig}
        />
      </>
    );
  }

  if (!hasDeepLinkGUID || selectedModalityOption === 'browser') {
    //     if (isMobile) {
    //         return <MobileScreen />;
    //     }

    if (browserName === 'Firefox') {
      return <MemoUnsupportedBrowser />;
    }
  }

  return (
    <div id='container' className={`${fullscreenExternal && 'maximize'}`}>
      {!gettingModalityOptions && // Prevent the Player from loading prematurely while determining Modality Options (ONLY when hasDeepLinkGUID).
        orgId && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div id='navWithPlayer'>
              <Navigation
                contentTitle={contentTitle}
                playerStatus={playerStatus}
                fullscreenExternal={fullscreenExternal}
                setFullscreenExternal={setFullscreenExternal}
                orgId={orgId}
                userRole={props?.userRole}
                forceSendRXDEvents={forceSendRXDEvents}
                systemConfig={props?.systemConfig}
                userUrls={props?.userUrls}
                showPlayerControls={true}
              />
            </div>
            <div id='player'>
              <DexieProvider>
                <WrappedWebGLPlayer
                  orgId={orgId}
                  deepLinkGUID={deepLinkGUID}
                  debugPlayer={debugPlayer}
                  debugRxd={debugRxd}
                  unityVersion={unityVersion}
                  onPlayerStatus={onPlayerStatus}
                  fullscreenExternal={fullscreenExternal}
                  setFullscreenExternal={setFullscreenExternal}
                  forceSendRXDEvents={forceSendRXDEvents}
                  startPolling={startPolling}
                  stopPolling={stopPolling}
                  systemConfig={props?.systemConfig}
                />
              </DexieProvider>
            </div>
          </Box>
        )}
    </div>
  );
};

export default Main;
