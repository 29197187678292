import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleVector from '../../assets/Circle-Vector.png';
import backgroundVideo from '../../assets/TSA_Background_720p.mp4';
import backgroundImg from '../../assets/TSA_fallback_bg_img.webp';
import { logout } from '../../utils';
import Button from '../Global/components/Button';
import MemoConnectivityIssueIcon from '../svg-icons/ConnectivityIssueIcon';
import MemoErrorIcon from '../svg-icons/ErrorIcon';

const rxd = (window as any).RXD;

type MessageType =
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'connectivity-issue';

interface Props {
  messageType: MessageType;
  messageTitle: string;
  messageText: string;
  exitButton?: string | null;
  transparentBackground?: boolean;
}

const LoginMessagesPage = (props: Props) => {
  const {
    messageType,
    messageTitle,
    messageText,
    exitButton,
    transparentBackground = true,
  } = props;
  const Icon =
    messageType !== 'connectivity-issue'
      ? MemoErrorIcon
      : MemoConnectivityIssueIcon;

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{
        width: '100vw',
        height: '100vh',
      }}
      className={transparentBackground ? '' : 'animated-video-bg'}
    >
      <video autoPlay loop muted playsInline className='animated-video'>
        <source src={backgroundVideo} type='video/mp4' />
      </video>
      <picture>
        <source src={backgroundImg} type='image/webp' />
        <img
          src={backgroundImg}
          alt='background'
          className='fallback-img'
        />
      </picture>
      <CssBaseline />
      <Box
        sx={{
          mt: '-70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className='highlight-icon' style={{ bottom: -35 }}>
          <img
            alt='Circle Vector'
            src={CircleVector}
            width={100}
            height={100}
            className='circle'
          />
          <Icon color='white' className='lock' />
          <div className='background-circle' />
        </div>
        <Box
          sx={{
            padding: '43px 75px 34px 75px',
            background: 'rgba(16, 18, 31, 0.80)',
            borderRadius: '24px',
          }}
          style={{ width: '600px' }}
        >
          <Typography variant='subtitle1' align='center' sx={{ fontSize: 24 }}>
            {messageTitle}
          </Typography>

          <Typography
            variant='subtitle1'
            textAlign='center'
            sx={{
              color: 'text.primary',
              lineHeight: '24px',
              fontFamily: 'Roboto Regular',
            }}
            mt={2}
          >
            {messageText}
          </Typography>
        </Box>

        <Box
          component='form'
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '1.5rem auto',
          }}
          noValidate
        >
          {exitButton && (
            <Button
              onClick={() => {
                setTimeout(() => {
                  logout();
                  if (rxd) {
                    rxd?.ConcedeControl();
                    console.log('RXD: ConcedeControl() called');
                  }
                }, 1000);
              }}
            >
              {exitButton}
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default LoginMessagesPage;
