import { useCallback, useEffect, useState } from 'react';
import { UnityContextHook } from 'react-unity-webgl/distribution/types/unity-context-hook';
import { performanceLogger } from '../helpers';

export const useGetMessageFromUnity = (unityContext?: UnityContextHook, debugPlayer?: boolean) => {
    const [formattedMessage, setFormattedMessage] = useState();

    const getMessageFromUnity = useCallback(
        (objectMain: any) => {
            const obj = JSON.parse(objectMain);
            const formatted = { ...obj, customData: JSON.parse(obj.customData) };

        performanceLogger('useGetMessageFromUnity',  formatted?.eventName, formatted?.customData?.message)

            if (debugPlayer)
                console.info(
                    `\n%c UnityToReact: ${formatted?.eventName} `,
                    'background:blue; color:white; font-weight:600;',
                    formatted,
                );
            setFormattedMessage(formatted);
        },
        [debugPlayer],
    );

    useEffect(() => {
        unityContext?.addEventListener('BridgeUnityToReact', getMessageFromUnity);
        return () => {
            unityContext?.removeEventListener('BridgeUnityToReact', getMessageFromUnity);
        };
    }, [getMessageFromUnity, unityContext]);

    return formattedMessage as any;
};
