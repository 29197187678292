import packageJson from '../../package.json';
// Import SVG icons.
import Spinner from '../assets/Spinner-Animated.png';
import MemoLessThan from '../components/svg-icons/LessThan';
import MemoUserIcon from '../components/svg-icons/UserIcon';
import * as Utils from '../utils';

const buildNumber = process.env.REACT_APP_BUILD_NUMBER || '';

const unityVersion = process.env.REACT_APP_UNITY_VERSION || '';

const BrandingObj = {
  main: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    borderColor: 'black',
    width: 540,
    marginTop: '2px',
    padding: '80px 54.4px 10px 54.4px',
    backgroundColor: 'rgb(16, 18, 31)',
    borderRadius: '24px',
  },
  paragraph: {
    fontSize: 14,
    color: '#fff',
    textAlign: 'center',
    lineHeight: '18px',
  },
  authHeader: {
    headerIcon: (
      <MemoUserIcon
        color='white'
        style={{
          position: 'absolute',
          top: '30%',
          zIndex: 1,
        }}
      />
    ),
    headingText: Utils.getOrgId(),
    general: {
      color: '#ffffff',
      fontFamily: 'Roboto SemiBold',
      letterSpacing: '0.68px',
      fontSize: '24px',
      lineHeight: '37px',
      fontWeight: 500,
      textShadow: '0rem 0rem .625rem #0000001A',
      marginTop: '2.7rem',
      textAlign: 'center',
      position: 'relative',
      zIndex: 2,
    },
    subtitle: {
      fontFamily: 'Roboto SemiBold',
      letterSpacing: '0.75px',
      fontSize: '15px',
      lineHeight: '35px',
      fontWeight: 500,
      color: '#adc2cc',
      textAlign: 'center',
    },
  },
  column: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  form: {
    margin: '0.5rem auto',
    width: '100%',
    input: {
      textAlign: 'center',
      height: '1.4375rem',
      outline: 'none',
      padding: '8.5px .875rem',
      backgroundColor: '#3b3e50',
      color: '#adc2cc',
      borderColor: '#3b3e50',
      borderRadius: '8px',
      borderWidth: '2px',
      fontSize: '0.8125rem',
      lineHeight: '16px',
      letterSpacing: '.6496px',
      borderStyle: 'solid',
      fontWeight: 600,
      hover: {
        borderColor: '#ffc440',
        color: 'white',
      },
      focused: {
        color: 'white',
        borderColor: '#ffc440',
        borderWidth: '2px',
        height: '35px',
        padding: '8.5px 13px', // Reduce the left and right padding to factor in the borderWidth increase to keep the text fixed.
        backgroundColor: 'rgb(59, 62, 80)',
      },
      webkitAutofill: {
        textFillColor: 'white',
        boxShadow: '0 0 0 1000px #3b3e50 inset',
      },
      infoText: {
        margin: 0,
        textAlign: 'center',
        color: '#adc2cc',
        fontFamily: 'Roboto Italic',
        fontSize: '.8125rem',
        lineHeight: '1.125rem',
        letterSpacing: '0.65px',
        fontWeight: 600,
      },
      button: {
        primary: {
          boxSizing: 'border-box',
          margin: '1.3rem auto 0',
          padding: '0.625rem',
          outline: '0px',
          border: '0px',
          cursor: 'pointer',
          borderRadius: '18px',
          font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
          letterSpacing: '0.0406rem',
          display: 'block',
          textTransform: 'capitalize',
          background:
            'transparent linear-gradient(276deg, #ffad12 0%, #ffc331 100%) 0% 0% no-repeat padding-box',
          color: 'rgba(16, 18, 31, 0.8)',
          backgroundColor: '#FFC331',
          hover: {
            textDecoration: 'none',
            backgroundColor: '#FFFFFF76',
          },
          width: '258px',
          height: '35px',
          disabled: {
            background: '#cccccc',
            color: '#666666',
            cursor: 'inherit',
          },
        },
        secondary: {
          boxSizing: 'border-box',
          margin: '1.3rem auto 0',
          padding: '0.625rem',
          outline: '0px',
          border: '1px solid #fff',
          cursor: 'pointer',
          borderRadius: '5px',
          font: 'normal normal 600 0.8125rem/1rem Montserrat Medium',
          letterSpacing: '0.0406rem',
          display: 'block',
          textTransform: 'capitalize',
          background: 'transparent #9e9e9e 0% 0% no-repeat padding-box',
          color: 'white',
          backgroundColor: 'transparent',
          hover: {
            textDecoration: 'none',
          },
          width: '100%',
          height: '35px',
          disabled: {
            background: '#cccccc',
            color: '#666666',
            cursor: 'inherit',
          },
        },
      },
      buttonLink: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        margin: '1rem auto 0',
        padding: '0',
        textAlign: 'center',
        display: 'block',
        color: 'white',
        font: 'normal normal 400 0.8125rem/1rem Montserrat Medium',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      helperText: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
        textAlign: 'center',
        color: '#fff',
        minHeight: '.7rem',
      },
      disabled: {
        backgroundColor: 'inherit',
        color: 'inherit',
        borderColor: 'inherit',
      },
      error: {
        backgroundColor: 'inherit',
        color: '#fff',
        borderColor: 'inherit',
        fontSize: 16,
        textAlign: 'center',
      },
    },
  },
  footer: {
    marginTop: '.5rem',
  },
  loadingIcon: (
    <img src={Spinner} alt='loading...' className='loading-spinner' />
  ),
  copyright: `© ${new Date().getFullYear()} Talespin Reality Labs, Inc.`,
  version: `(Version ${packageJson.version}${
    buildNumber ? `-${buildNumber}` : ''
  })
  Streaming Player: ${unityVersion}`,
  messageBox: {
    error: {},
    info: {
      backgroundColor: '#0288d1',
    },
    success: {
      backgroundColor: '#388e3c',
    },
    warning: {
      backgroundColor: '#f57c00',
    },
    color: '#ffffff',
    fontSize: '13px',
    fontHeight: '18px',
    fontWeight: 500,
    fontFamily: 'Roboto Regular',
    fontStyle: 'italic',
    letterSpacing: '.0406rem',
    borderRadius: '5px',
    lineHeight: 1.43,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tos: {
    mainLogoUrl:
      'https://api.bc.talespin.io/files/portal/g/30034/cust/talespin_dev/V1/Talespin_Logo_Yellow_Dev.png',
    mainLogoDisplayWidth: '234',
    backLinkIcon: <MemoLessThan fontSize={50} fill='#ffc100' />,
    dialogTitle: {
      color: '#ffffff',
      fontFamily: 'Roboto SemiBold',
      letterSpacing: '0.68px',
      fontSize: '1.25rem',
      lineHeight: 1,
      fontWeight: 500,
      textShadow: '0rem 0rem .625rem #0000001A',
      margin: '.5rem',
    },
    version: {
      fontSize: '1rem',
    },
    checkboxText: {
      color: '#ffad12',
      fontFamily: 'Roboto SemiBold',
      fontWeight: 400,
      fontSize: '.875rem',
      lineHeight: '20px',
    },
    infoText: {
      margin: 0,
      textAlign: 'center',
      color: '#adc2cc',
      fontFamily: 'Roboto Italic',
      fontSize: '14px',
      lineHeight: '1.2rem',
      letterSpacing: '0.65px',
      fontWeight: 600,
    },
    termsText: {
      fontFamily: 'Roboto Regular',
      letterSpacing: '0.65px',
      fontSize: '14px',
      lineHeight: '1.4rem',
      fontWeight: 400,
    },
    termsLink: {
      color: '#ffffff',
    },
  },
  dataConsentDialog: {
    dialogMask: {
      background: '#000000 0% 0% no-repeat padding-box',
      opacity: '0.3',
    },
    dialog: {
      width: '450px',
      height: 'fit-content',
      padding: '30px',
      background:
        'transparent radial-gradient(closest-side at 50% 0%, #383C51 0%, #292C3C 22%, #292C3C 100%) 0% 0% no-repeat padding-box',
      borderRadius: '0',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    },
    dialogTitle: {
      color: '#ffffff',
      fontFamily: 'Roboto Bold',
      letterSpacing: 0,
      fontSize: '18px',
      lineHeight: '20px',
      fontWeight: 600,
      textShadow: '0rem 0rem .625rem #0000001A',
      margin: '0 0 18px',
      padding: '0 0 10px',
      borderBottom: '1px solid #E0E0E0',
    },
    infoTextHeading: {
      margin: '11px 0',
      textAlign: 'left',
      color: '#ffffff',
      fontFamily: 'Montserrat SemiBold',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0',
      fontWeight: 600,
    },
    infoText: {
      margin: '11px 0',
      textAlign: 'left',
      color: '#ADC2CC',
      fontFamily: 'Montserrat Medium',
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '0',
      fontWeight: 'normal',
    },
    infoTextLink: {
      color: '#ffc100',
    },
    privacyPolicyLink: {
      url: 'https://www.talespin.com/privacy-policy',
    },
  },
};

export default BrandingObj;
