import { createTheme } from '@mui/material/styles';

// A custom theme for this app.
const theme = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#556cd6',
      light: 'rgba(16, 18, 31, 0.8)',
    },
    secondary: {
      main: '#A3A5AD',
    },
    error: {
      main: '#ED4343',
    },
    warning: {
      main: '#FA4516',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#2e7d32',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#ADC2CC',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Roboto SemiBold',
      letterSpacing: '0.68px',
      fontSize: '34px',
      lineHeight: '37px',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Roboto SemiBold',
      letterSpacing: 0,
      fontSize: '30px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: 'Roboto SemiBold',
      letterSpacing: '0.75px',
      fontSize: '15px',
      lineHeight: '35px',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Roboto Regular',
      letterSpacing: '0.75px',
      fontSize: '15px',
      lineHeight: '35px',
      fontWeight: 500,
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(16, 18, 31, 0.8)',
          backgroundImage: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '.8125rem',
        },
        filledError: {
          backgroundColor: '#ED4343',
        },
        filledWarning: {
          backgroundColor: '#ed6c02',
        },
        filledInfo: {
          backgroundColor: '#0288d1',
        },
        filledSuccess: {
          backgroundColor: '#2e7d32',
        },
        icon: {
          alignItems: 'center',
          fontSize: '1.875rem',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#A3A5AD',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          font: 'normal normal normal 0.8125rem/1rem Roboto Bold',
          display: 'block',
          textTransform: 'capitalize',
        },
        containedPrimary: {
          backgroundColor: '#FFC331',
          color: 'rgba(16, 18, 31, 0.8)',
          '&:hover': {
            textDecoration: 'none',
            background: '#FAD349',
          },
        },
        containedSecondary: {
          backgroundColor: '#9e9e9e',
          color: 'rgba(16, 18, 31, 0.8)',
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#FFFFFF',
          },
        },
        outlinedPrimary: {
          backgroundColor: 'transparent',
          color: '#FFFFFF',
          border: '3px solid #FFFFFF',
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#FFFFFF',
            color: 'rgba(16, 18, 31, 0.8)',
            border: '3px solid #FFFFFF',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          display: 'flex',
          width: '100vw',
          height: '100vh',
        },
        '#root': {
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#ffc440',
              color: 'white',
              fontFamily: 'Montserrat SemiBold',
            },
        },
        input: {
          '&.MuiOutlinedInput-input': {
            background: '#3b3e50 0% 0% no-repeat padding-box',
            fontFamily: 'Montserrat Medium',
            fontSize: '0.8125rem',
            lineHeight: '1rem',
            color: '#adc2cc',
            letterSpacing: '0.0406rem',
            boxShadow: '0rem 0.125rem 0.1875rem #0000001a',
            borderColor: '#adc2cc',
            borderRadius: '0.3125rem',
            '&:focus + fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ffc440',
              color: 'white',
              fontFamily: 'Montserrat SemiBold',
            },
            '&:-webkit-autofill': {
              boxShadow: '0 0 0 62.5rem #3b3e50 inset',
              WebkitBoxShadow: '0 0 0 62.5rem #3b3e50 inset',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            fontSize: '0.9rem',
            fontFamily: 'Montserrat Medium',
            '&.Mui-focused': {
              borderColor: '#ffc440',
              color: '#FFFFFF',
              fontFamily: 'Montserrat SemiBold',
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '6px',
          borderRadius: '10px',
        },
      },
    },
  },
});

export default theme;
