function VrIcon(props: React.SVGProps<SVGSVGElement>) {
  const size = props.fontSize || '96';
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      className={props.className || ''}
      width={size}
      height={size}
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 58C6 43.6406 17.6406 32 32 32H64C78.3594 32 90 43.6406 90 58V58C90 72.3594 78.3594 84 64 84H32C17.6406 84 6 72.3594 6 58V58Z'
        stroke={color}
        strokeWidth='3'
      />
      <path
        d='M36.9907 17.8532C36.9907 16.7486 37.8862 15.8532 38.9907 15.8532H56.1283C57.2329 15.8532 58.1283 16.7486 58.1283 17.8532V31.7064H36.9907V17.8532Z'
        stroke={color}
        strokeWidth='3'
      />
      <circle cx='31.5' cy='71.5' r='1.5' fill={color} />
      <circle cx='63.5' cy='71.5' r='1.5' fill={color} />
    </svg>
  );
}

export default VrIcon;
