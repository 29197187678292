import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

// Import SVG icons.
import MemoBrowserIcon from '../svg-icons/BrowserIcon';

// Import types
import { ModalityOption } from '../../types/ModalityOption';
import MemoVrIcon from '../svg-icons/VrIcon';
import { MemoModalityButton } from './ModalityButton';

interface Props {
  contentTitle: string;
  modalityOptions: ModalityOption[];
  onModalityOptionSelection: (selectedModalityOption: ModalityOption) => void;
  systemConfig: { [index: string]: any };
}

const LandingPage = (props: Props) => {
  const { modalityOptions, onModalityOptionSelection } = props;
  const [selectedModalityOption, setSelectedModalityOption] =
    useState<ModalityOption | null>(null);
  const showVRInstructions =
    !!props.systemConfig?.showTSAVRInstallationInstructions;

  useEffect(() => {
    if (modalityOptions.length === 1) {
      // If single modality option then bypass presenting the landing page and go directly to that modality.
      setSelectedModalityOption(modalityOptions[0]);
    }
  }, [modalityOptions]);

  useEffect(() => {
    if (selectedModalityOption) {
      onModalityOptionSelection(selectedModalityOption);
    }
  }, [selectedModalityOption, onModalityOptionSelection]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: -2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          padding: { xs: '0 20px' },
        }}
      >
        <Typography variant='h2' align='center' sx={{ marginBottom: '4rem' }}>
          How would you like to experience the module?
        </Typography>
      </Box>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={{ xs: 2, md: '60px' }}
        columns={{ xs: 1, md: 12 }}
        sx={{
          width: { md: '1000px' },
          padding: { xs: '0 1.25rem' },
        }}
      >
        <MemoModalityButton
          setSelectedModalityOption={setSelectedModalityOption}
          modalityOptions={modalityOptions}
          modality='vr'
          heading='Headset'
          icon={MemoVrIcon}
          title='VR App'
        />

        <MemoModalityButton
          setSelectedModalityOption={setSelectedModalityOption}
          modalityOptions={modalityOptions}
          modality='browser'
          heading='Desktop Browser'
          icon={MemoBrowserIcon}
          title='Web App'
        />
      </Grid>
      {showVRInstructions && (
        <Box
          mt={6}
          sx={{
            padding: { xs: '0 20px 60px 0' },
          }}
        >
          <Typography letterSpacing={0} align='center'>
            Talespin VR for Business App is{' '}
            <Link
              sx={{
                color: 'text.primary',
                textDecoration: 'underline',
              }}
              href={
                'https://cornerstoneondemand.my.site.com/s/articles/IMMERSE-Install-the-Talespin-for-Business-VR-app'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              only available here.
            </Link>
          </Typography>
          <Typography align='center' letterSpacing={0} lineHeight={2}>
            Check out tips and instructions for the best experience in{' '}
            <Link
              sx={{
                color: 'text.primary',
                textDecoration: 'underline',
              }}
              href={
                'https://cornerstoneondemand.my.site.com/s/articles/IMMERSE-Let-s-Get-Started-Best-Practices-for-VR'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              VR
            </Link>{' '}
            or{' '}
            <Link
              sx={{
                letterSpacing: '0px',
                color: 'text.primary',
                textDecoration: 'underline',
                cursor: 'hand',
              }}
              href={
                'https://cornerstoneondemand.my.site.com/s/articles/IMMERSE-Let-s-Get-Started-Best-Practices-for-Web'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              Web
            </Link>
            .
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LandingPage;
