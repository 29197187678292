import { Box, useTheme } from '@mui/material';
import MemoWarningIcon from '../../svg-icons/WarningIcon';

interface Props {
  children: string;
  type?: 'warning'; // Support more types in the future, ex. error
}

const AlertBanner = ({ children, type = 'warning' }: Props) => {
  const theme = useTheme();
  const containerStyle = {
    position: 'absolute',
    top: 64, // Account for Navbar height
    width: '100%',
  };

  const VARIANTS = {
    warning: {
      padding: '16px',
      color: '#FFFFFF',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
      background: theme.palette.warning.main,
    },
  };

  return (
    <Box sx={containerStyle}>
      <Box sx={VARIANTS[type]}>
        <MemoWarningIcon color='white' style={{ paddingBottom: 2 }} />
        <Box p={1}>{children}</Box>
      </Box>
    </Box>
  );
};

export default AlertBanner;
