import * as React from 'react';

function MinimizeScreenIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 9L9 9L9 4'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 15L15 15L15 20'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 4L15 9L20 9'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 20L9 15L4 15'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const MemoMinimizeScreenIcon = React.memo(MinimizeScreenIcon);
export default MemoMinimizeScreenIcon;
