/**
 * This file was mainly copied from CPDWeb project https://github.com/talespin-vr/talespin-copilot-designer-web/blob/develop/src/analytics/analytics.ts
 * See comments in https://talespin.atlassian.net/browse/APPSERV-634 for more info on the bug that had to be resolved by overriding the Posthog plugin.
 *
 */

import Analytics from 'analytics';
import postHogPlugin from '@metro-fs/analytics-plugin-posthog';
import posthog from 'posthog-js';

const postHogToken = process.env.REACT_APP_ANALYTICS_POSTHOG_TOKEN;

const posthogOverrides = {
  identify: ({ payload }: any) => {
    if (payload.userId) {
      var setProperties = payload.traits;
      let orgId = payload.traits.organizationId;
      posthog.identify(`${orgId}-${payload.userId}`, setProperties);
    }
  },

  track: ({ payload }) => {
    console.log(`Posthog: Track > ${payload.event}`);
    var eventCategory = payload.properties.category;
    var eventAction = payload.event;
    var eventName = payload.properties.name;
    var eventValue = payload.properties.value;
    var eventCount = payload.properties.count;
    posthog.capture(eventName, { 
      category: eventCategory,
      action: eventAction,
      name: eventName,
      value: eventValue,
      count: eventCount || 1,
    });
  },
};

const customPosthog = Object.assign(
  {},
  postHogPlugin({
    token: postHogToken || '',
    enabled: true,
    options: {
      debug: process.env.NODE_ENV === 'development',
      persistence: 'memory',
      disable_cookie: true,
    },
  }),
  posthogOverrides,
);

/* 
      initialize analytics and load plugins 
      TODO: load this dynamically based on org config?
  */
const analytics = Analytics({
  app: 'TSA StreamingPlayer',
  debug: process.env.NODE_ENV === 'development',
  plugins: [
    // this customization of the plugin fixes a .identify bug mentioned here: https://github.com/metro-fs/analytics-plugin-posthog/issues/3
    customPosthog,
  ],
});

var registeredPlugins: any = [];

analytics.on('registerPlugins', ({ payload }) => {
  console.log('registering plugins: ', JSON.stringify(payload.plugins));
  let plugins = payload.plugins;
  plugins.forEach((pluginName: string) => {
    registeredPlugins.push(pluginName);
  });

  // reset enabled plugins from session storage
  plugins = localStorage.getItem('analyticsPlugins');
  if (plugins != null) {
    var pluginNames: string[] = JSON.parse(plugins);
    setAnalyticsPlugins(pluginNames);
  }
});

analytics.on('enablePlugin', ({ payload }) => {
  console.log('enabling plugins: ', JSON.stringify(payload.plugins));
});

analytics.on('disablePlugin', ({ payload }) => {
  console.log('disabling plugins: ', JSON.stringify(payload.plugins));
});

export const setAnalyticsPlugins = (pluginNames: string[]) => {
  localStorage.setItem('analyticsPlugins', JSON.stringify(pluginNames));
  var promises: any = [];
  registeredPlugins.forEach((pluginName: string) => {
    var promise: Promise<any>;
    if (pluginNames.includes(pluginName)) {
      promise = analytics.plugins.enable(pluginName);
    } else {
      promise = analytics.plugins.disable(pluginName, () => {
        // not sure why this is required
      });
    }
    promises.push(promise);
  });
  return Promise.all(promises);
};

export default analytics;
