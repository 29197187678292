import { useCallback, useEffect, useState } from 'react';

// Import types.
import DocElmt from '../types/DocElmt';

export const useRequestFullscreen = (
  fullscreenExternal: boolean,
  setFullscreenExternal: (maxScreenExt: boolean) => void,
  debugPlayer: boolean,
) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = async () => {
    const isFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      ((document as any).webkitFullscreenElement &&
        (document as any).webkitFullscreenElement !== null) ||
      ((document as any).mozFullScreenElement &&
        (document as any).mozFullScreenElement !== null) ||
      ((document as any).msFullscreenElement &&
        (document as any).msFullscreenElement !== null);

    const docElmt: DocElmt = document.documentElement;

    if (!isFullScreen) {
      if (docElmt.requestFullscreen) {
        try {
          await docElmt.requestFullscreen();
        } catch (error: any) {
          console.log('requestFullscreen() Error', error);
        }
      } else if (docElmt.msRequestFullscreen) {
        docElmt.msRequestFullscreen();
      } else if (docElmt.mozRequestFullScreen) {
        docElmt.mozRequestFullScreen();
      } else if (docElmt.webkitRequestFullScreen) {
        docElmt.webkitRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitCancelFullScreen) {
        (document as any).webkitCancelFullScreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const fullscreenChangeListener = (_event: Event) => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (
        document.fullscreenElement /* Standard syntax */ ||
        (document as any)
          .webkitFullscreenElement /* Safari and Opera syntax */ ||
        (document as any).msFullscreenElement /* IE11 syntax */
      ) {
        if (debugPlayer) console.log(`Entered full-screen mode.`);
      } else {
        if (debugPlayer) console.log('Leaving full-screen mode.');
        setIsFullscreen(false);
      }
    };

    const fullscreenEventListeners = () => {
      document.addEventListener('fullscreenchange', fullscreenChangeListener);
      document.addEventListener(
        'webkitfullscreenchange',
        fullscreenChangeListener,
      );
      document.addEventListener('msfullscreenchange', fullscreenChangeListener);
    };

    const removeFullscreenEventListeners = () => {
      document.removeEventListener(
        'fullscreenchange',
        fullscreenChangeListener,
      );
      document.removeEventListener(
        'webkitfullscreenchange',
        fullscreenChangeListener,
      );
      document.removeEventListener(
        'msfullscreenchange',
        fullscreenChangeListener,
      );
    };

    fullscreenEventListeners();

    return () => {
      removeFullscreenEventListeners();
    };
  }, [debugPlayer]);

  const maximizePlayer = useCallback(async () => {
    if (debugPlayer) console.log('TSA-WebGL: Call maximize');
    // requestFullscreen(true);
    await toggleFullscreen();
    setIsFullscreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const minimizePlayer = useCallback(async () => {
    if (debugPlayer) console.log('TSA-WebGL: Call minimize');
    // requestFullscreen(false);
    await toggleFullscreen();
    setIsFullscreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fullscreenExternal) {
      maximizePlayer();
    }
  }, [fullscreenExternal, maximizePlayer]);

  useEffect(() => {
    if (!fullscreenExternal && isFullscreen) {
      minimizePlayer();
    }
  }, [isFullscreen, fullscreenExternal, minimizePlayer]);

  useEffect(() => {
    setFullscreenExternal(isFullscreen);
  }, [isFullscreen, setFullscreenExternal]);

  return [isFullscreen] as [boolean];
};
