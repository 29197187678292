import Dexie from 'dexie';
import { createContext } from 'react';

interface DexieProviderProps {
  children: React.ReactElement;
}

export const DexieContext = createContext({
  db: {} as any,
});
const DexieProvider = ({ children }: DexieProviderProps) => {
  const db = new Dexie('unityBuild');
  db.version(11).stores({
    build: '++id, loader,data,framework,code,modifiedDate',
  });

  return (
    <DexieContext.Provider
      value={{
        db,
      }}
    >
      {children}
    </DexieContext.Provider>
  );
};

export default DexieProvider;
