import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import * as api from '../../apis';
import * as Utils from '../../utils';
import Button from '../Global/components/Button';

// Import SVG icons.
import MemoLockIcon from '../svg-icons/LockIcon';

// Import types.
import CircleVector from '../../assets/Circle-Vector.png';
import { ModalityOption } from '../../types/ModalityOption';
import AlertBanner from '../Global/components/AlertBanner';

const rxd = (window as any).RXD;

interface Props {
  contentTitle: string;
  modalityOptions: ModalityOption[] | null;
  onModalityOptionSelection: (
    selectedModalityOption: ModalityOption | null,
  ) => void;
  startPolling: () => void;
  stopPolling: () => void;
  deepLinkGUID?: string;
  systemConfig: { [index: string]: any };
}

const setupInstructions = (props: Props) => {
  const showVRInstructions =
    !!props.systemConfig?.showTSAVRInstallationInstructions;

  return (
    <Box style={{ margin: '32px auto 35px' }}>
      {showVRInstructions ? (
        <>
          <p>
            1.{' '}
            <Link
              sx={{
                textDecoration: 'none',
                letterSpacing: '0px',
                color: '#BBD4DD',
                cursor: 'hand',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              href={
                'https://cornerstoneondemand.my.site.com/s/articles/IMMERSE-Install-the-Talespin-for-Business-VR-app'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              Install the Talespin for Business VR app
            </Link>{' '}
            if you don't already have it installed.
          </p>
          <p>2. Launch the Talespin for Business app on your VR device.</p>
          <p>
            3. Select the <b>"Enter One-Time Password"</b> button.
          </p>
          <p>
            4. Enter your One-Time Password listed above and select the{' '}
            <b>"Log In"</b> button to access training.
          </p>
        </>
      ) : (
        <>
          <p>1. Launch the Talespin for Business app on your VR device.</p>
          <p>
            2. Select the <b>"Enter One-Time Password"</b> button.
          </p>
          <p>
            3. Enter your One-Time Password listed above and select the{' '}
            <b>"Log In"</b> button to access training.
          </p>
        </>
      )}
    </Box>
  );
};

const OneTimePassword = (props: Props) => {
  const { startPolling, stopPolling, deepLinkGUID } = props;
  const [pin, setPin] = useState<string | null>(null);
  const [pinIsLoading, setPinIsLoading] = useState(false);
  const [shortPinData, setShortPinData] = useState<{
    [key: string]: any;
  } | null>(null);
  const [pinHasExpired, setPinHasExpired] = useState(false);
  const [expiryMessage, setExpiryMessage] = useState('');

  useEffect(() => {
    const getOneTimePassword = async () => {
      try {
        if (rxd) stopPolling(); // Stop RXD Polling before making API call.

        setPinIsLoading(true);
        const data = await api.getTSAShortPin(deepLinkGUID);
        const response1 = data?.data?.response;
        if (response1 && response1?.displayPin) {
          setPin(response1?.displayPin);
          setShortPinData(response1);
        } else if (!response1?.displayPin) {
          // Covers the case where the initial response from /script/run 's
          // bh_cpd/createTSAShortPin is successful but the displayPin is not returned.
          console.log(
            'Retrying to get One Time Password because a pin was not returned.',
          );
          const data = await api.getTSAShortPin(deepLinkGUID);
          const response2 = data?.data?.response;
          if (response2 && response2?.displayPin) {
            setPin(response2?.displayPin);
            setShortPinData(response2);
          }
        }
      } catch (error: any) {
        console.log('error getting One Time Password', error);
      } finally {
        setPinIsLoading(false);

        if (rxd) startPolling(); // Resume RXD Polling after the API call has finished.
      }
    };
    if (!pinHasExpired) getOneTimePassword();
  }, [deepLinkGUID, pinHasExpired, startPolling, stopPolling]);

  // Countdown timer.
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const startTimer = () => {
      timer = setInterval(() => {
        const expireDate = shortPinData?.expireDate;
        if (!expireDate) return;

        let timeLeft = parseInt(expireDate.toString()) - Date.now() - 1000; // For testing use 590000 to set the countdown to 10 seconds.
        const expired = timeLeft < 1000; // When less than a second left.

        if (expired) {
          setPinHasExpired(expired);
          setExpiryMessage(`Password expired 00:00`);
        } else {
          setExpiryMessage(
            `Password expires in ${Utils.millisecondsToMinutesAndSeconds(
              timeLeft,
            )}`,
          );
        }
      }, 1000);
    };

    if (shortPinData && !pinIsLoading) {
      startTimer();
    }

    return () => {
      // Clear the setInterval() timer in this clean-up function.
      clearInterval(timer);
    };
  }, [shortPinData, pinIsLoading]);

  const onRefreshPassword = () => {
    setPinHasExpired(false);
    setPin(null);
  };

  const onBackButtonClick = () => {
    props.onModalityOptionSelection(null);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <CssBaseline />
      {rxd && (
        <AlertBanner>
          This browser window must remain open during your VR session. DO NOT
          close this window.
        </AlertBanner>
      )}
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className='highlight-icon' style={{ bottom: -35 }}>
          <img
            alt='Circle Vector'
            src={CircleVector}
            width={100}
            height={100}
            className='circle'
          />
          <MemoLockIcon color='white' className='lock' />
          <div className='background-circle' />
        </div>
        <Box
          sx={{
            padding: '55px 75px 34px 75px',
            background: 'rgba(16, 18, 31, 0.80)',
            borderRadius: '24px',
          }}
          style={{ width: '600px' }}
        >
          <Typography variant='subtitle1' align='center' sx={{ fontSize: 24 }}>
            Your One-Time Password:
          </Typography>

          <Box
            style={{
              width: '100%', // Fix IE 11 issue.
              margin: '0.5rem auto',
              padding: '16px 0',
              background: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <Typography
              style={{
                textAlign: 'center',
                font: '34px/54px Roboto SemiBold',
                letterSpacing: '4.5px',
                color: `${pinHasExpired ? '#A3A5AD' : '#FFFFFF'}`,
                textShadow: '0px 0px 10px #0000001A',
              }}
            >
              {!pinIsLoading && pin ? (
                pin
              ) : (
                <CircularProgress color={'inherit'} size={25} />
              )}
            </Typography>
            <Typography
              style={{
                textAlign: 'center',
                font: 'normal normal bold 18px/24px Roboto Regular',
                letterSpacing: '0.75px',
                color: `${pinHasExpired ? '#EB4141' : '#FFC331'}`,
              }}
            >
              {expiryMessage || `Password expires in 00:00`}
            </Typography>
          </Box>

          <Box style={{ margin: '32px auto 35px' }}>
            <Typography
              variant='subtitle1'
              sx={{
                color: 'text.primary',
                lineHeight: '24px',
                my: '14px',
                fontFamily: 'Roboto Regular',
              }}
            >
              {setupInstructions(props)}
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Regular',
                fontStyle: 'italic',
                lineHeight: '24px',
              }}
            >
              NOTE: Talespin for Business app version 2.7.3 or higher is
              required to proceed with this login method.
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            margin: '25px 0',
          }}
        >
          {props.modalityOptions && props.modalityOptions.length > 1 && (
            <Button
              variant={pinHasExpired ? 'outlined' : 'contained'}
              onClick={onBackButtonClick}
            >
              Back
            </Button>
          )}

          {pinHasExpired && (
            <Button
              variant='contained'
              sx={{
                m: '0',
                p: '7px 42px',
                minWidth: '206px',
                background: '#FFC331',
                borderRadius: '24px',
                letterSpacing: '0.52px',
                color: 'rgba(16, 18, 31, 0.8)',
              }}
              onClick={onRefreshPassword}
            >
              Generate New Password
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default OneTimePassword;
