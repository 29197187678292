import { useEffect, useRef } from 'react';
import analytics from '../analytics/analytics';

// Setting types.
type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

const logout = () => {
  // Analytics: 'User Logout' event tracking.
  analytics.track('User Logout', {
    category: 'Logout',
    name: 'User Logout',
  });

  // Remove all saved data from sessionStorage.
  sessionStorage.clear();

  console.warn('User Logged out');
  // Reload the page so the user sees the login screen.
  window.location.reload();
};

const getOrgId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let orgIdParam =
    urlParams.get('orgId') || sessionStorage.getItem('orgId') || '';

  // Query string guard. Don't allow orgId value longer than 40 chars.
  if (orgIdParam.length > 40) {
    orgIdParam = '';
    console.error('ERROR: The orgId exceeds the maximum allowed length.');
  }

  if (orgIdParam !== sessionStorage.getItem('orgId')) {
    sessionStorage.setItem('orgId', orgIdParam);
  }

  return orgIdParam;
};

const isGuid = (guid: string) => {
  var regexGuid =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gi;
  return regexGuid.test(guid);
};

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */
const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

const millisecondsToMinutesAndSeconds = (ms: number) => {
  const m = Math.floor(ms / 60000)
    .toString()
    .padStart(2, '0');
  const s = Math.floor((ms % 60000) / 1000)
    .toString()
    .padStart(2, '0');
  return `${m}:${s}`;
};

// Convert content to base64 data.
const toDataURL = async (url: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
};

const getSubdomain = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const domains = parsedUrl.host.split('.');
    // Check if a subdomain exists
    if (domains.length <= 2) return '';
    return domains[0];
  } catch (error) {
    console.error(`Cannot parse the given URL: ${url}`, error);
    return '';
  }
};

export {
  getOrgId,
  getSubdomain,
  isGuid,
  logout,
  millisecondsToMinutesAndSeconds,
  toDataURL,
  useInterval,
};
