import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import BrandingObj from '../assets/Branding';

interface Props {
  styles?: React.CSSProperties;
  align?: 'left' | 'center' | 'right';
  showVersion?: boolean;
}

const Copyright = ({ styles, align, showVersion = true }: Props) => {
  return (
    <Container
      maxWidth='sm'
      sx={{ marginTop: '1.5rem' }}
      style={styles || undefined}
    >
      <CssBaseline />
      <Box>
        <Typography
          variant='body2'
          color='textSecondary'
          align={align || 'center'}
          sx={{
            fontSize: '0.6875rem',
            margin: '0.5rem auto',
            fontFamily: 'Montserrat Medium',
            ...styles,
          }}
        >
          {BrandingObj.copyright}
          {showVersion ? ` ${BrandingObj.version}` : ''}
        </Typography>
      </Box>
    </Container>
  );
};

export default Copyright;
