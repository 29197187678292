import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';

interface TableProps {
  rxd: any; // The RXD object of functions.
}

function BasicTable(props: TableProps) {
  const { rxd } = props;
  let currentScore = rxd?.GetScore() || 0;
  currentScore = Number.isNaN(currentScore) ? 0 : currentScore;
  const suspendData = `test suspend data ${Math.floor(
    Math.random() * 100,
  ).toString()}`;
  const bookmark = `test bookmark ${Math.floor(
    Math.random() * 100,
  ).toString()}`;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timestamp, setTimestamp] = useState<Date | null>(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const createData = (functionName: any, testAction: any, result: any) => {
    return { functionName, testAction, result };
  };

  const rows = [
    createData('GetStudentId()', '--', <div>{rxd?.GetStudentID()}</div>),
    createData('GetStudentName()', '--', <div>{rxd?.GetStudentName()}</div>),
    createData('GetLessonMode()', '--', <div>{rxd?.GetLessonMode()}</div>),
    createData(
      'SetScore(score: number, max: number, min: number)',
      <div>
        <button
          onClick={() => {
            rxd?.SetScore(currentScore + 5, 100, 0);
            setTimestamp(new Date());
          }}
        >
          SetScore({currentScore + 5}, 100, 0)
        </button>
      </div>,
      <div>Current Score: {currentScore}</div>,
    ),
    createData(
      'SetPassed()',
      <div>
        <button
          onClick={() => {
            rxd?.SetPassed();
          }}
        >
          SetPassed()
        </button>
      </div>,
      '--',
    ),
    createData(
      'SetFailed()',
      <div>
        <button
          onClick={() => {
            rxd?.SetFailed();
          }}
        >
          SetFailed()
        </button>
      </div>,
      '--',
    ),
    createData(
      'SetReachedEnd()',
      <div>
        <button
          onClick={() => {
            rxd?.SetReachedEnd();
            setTimestamp(new Date());
          }}
        >
          SetComplete/ReachedEnd
        </button>
      </div>,
      <div>Get Status: {rxd?.GetStatus()}</div>,
    ),
    createData(
      'ResetStatus()',
      <div>
        <button
          onClick={() => {
            rxd?.ResetStatus();
          }}
        >
          ResetStatus()
        </button>
      </div>,
      '--',
    ),
    createData(
      'SetSuspendData(val: string)',
      <div>
        <button
          onClick={() => {
            rxd?.SetSuspendData(suspendData);
            setTimestamp(new Date());
          }}
        >
          SetSuspendData({suspendData})
        </button>
      </div>,
      <div>GetSuspendData: {rxd?.GetSuspendData()}</div>,
    ),
    createData(
      'SetBookmark(val: string)',
      <div>
        <button
          onClick={() => {
            rxd?.SetBookmark(bookmark);
            setTimestamp(new Date());
          }}
        >
          SetBookmark({bookmark})
        </button>
      </div>,
      <div>GetBookmark: {rxd?.GetBookmark()}</div>,
    ),
    // createData(
    //     'RecordTrueFalseInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordTrueFalseInteraction(
    //                     'tf_123', // id - string identifier for the interaction.
    //                     true, // learnerResponse - learner's response to the interaction, format depends on the type of interaction.
    //                     true, // isCorrect - boolean indicating whether the learner's response was correct.
    //                     true, // correctResponse (optional) - expected correct response, format depends on the type of interaction.
    //                     'true/false interaction description', // description (optional) - string description of the interaction (i.e. the question asked).
    //                     1, // weighting (optional) - a number that indicates the importance of the interaction relative to other interactions.
    //                     1, // latency (optional) - number of milliseconds passed during interaction.
    //                     'lo_123' // learningObjectiveId (optional) - string identifier of a learning objective associated with this interaction.
    //                 );
    //             }}
    //         >
    //             RecordTrueFalseInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordMultipleChoiceInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordMultipleChoiceInteraction(
    //                     'mc_123',
    //                     'a',
    //                     true,
    //                     'a',
    //                     'multiple choice interaction description',
    //                     1,
    //                     1,
    //                     'lo_123'
    //                 );
    //             }}
    //         >
    //             RecordMultipleChoiceInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    createData(
      'RecordMultipleChoiceInteraction() (FAIL)',
      <div>
        <button
          onClick={() => {
            rxd?.RecordMultipleChoiceInteraction(
              'DEC-0',
              'How did you reach that decision? We want all our users to feel satisfied with our business and renewing is an important part of that.',
              false,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.05333333,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordMultipleChoiceInteraction
        </button>
      </div>,
      '--',
    ),
    createData(
      'RecordMultipleChoiceInteraction() (PASS)',
      <div>
        <button
          onClick={() => {
            rxd?.RecordMultipleChoiceInteraction(
              'DEC-1',
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              true,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.08,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordMultipleChoiceInteraction
        </button>
      </div>,
      '--',
    ),

    createData(
      'RecordFillInInteraction()',
      <div>
        <button
          onClick={() => {
            rxd?.RecordFillInInteraction(
              'DEC-3',
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              true,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.08,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordFillInInteraction
        </button>
      </div>,
      '--',
    ),
    createData(
      'RecordMatchingInteraction()',
      <div>
        <button
          onClick={() => {
            rxd?.RecordMatchingInteraction(
              'DEC-4',
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              true,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.08,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordMatchingInteraction
        </button>
      </div>,
      '--',
    ),
    createData(
      'RecordPerformanceInteraction()',
      <div>
        <button
          onClick={() => {
            rxd?.RecordPerformanceInteraction(
              'DEC-5',
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              true,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.08,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordPerformanceInteraction
        </button>
      </div>,
      '--',
    ),
    createData(
      'RecordSequencingInteraction()',
      <div>
        <button
          onClick={() => {
            rxd?.RecordSequencingInteraction(
              'DEC-6',
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              true,
              'I understand. It would be great if we could talk through your thoughts and can get some feedback on that.',
              "Anyway, I don't want to take up more of your time. I like you so I want to let you know: I don't see us renewing again. Long term, we're looking for a better fit.",
              0.08,
              0,
              'ActiveListeningBasic, ProbingQuestionsBasic, MotivatingBasic',
            );
          }}
        >
          RecordSequencingInteraction
        </button>
      </div>,
      '--',
    ),

    // createData(
    //     'RecordFillInInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordFillInInteraction(
    //                     'abc125',
    //                     'correct-response',
    //                     true,
    //                     'correct-response',
    //                     'fill In Description',
    //                     1,
    //                     0,
    //                     'strLearningObjectiveID'
    //                 );
    //             }}
    //         >
    //             RecordFillInInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordMatchingInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordMatchingInteraction(
    //                     'abc321',
    //                     { source: 'source1', target: 'target1' },
    //                     true,
    //                     { source: 'source1', target: 'target1' },
    //                     'matching interaction description',
    //                     1,
    //                     0,
    //                     'strLearningObjectiveID'
    //                 );
    //             }}
    //         >
    //             RecordMatchingInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordPerformanceInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordPerformanceInteraction(
    //                     'abc543',
    //                     'response',
    //                     true,
    //                     'response',
    //                     'performance interaction description',
    //                     1,
    //                     0,
    //                     'strLearningObjectiveID'
    //                 );
    //             }}
    //         >
    //             RecordPerformanceInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordSequencingInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordSequencingInteraction(
    //                     'abc654',
    //                     { short: 'a', long: 'alpha' },
    //                     true,
    //                     { short: 'a', long: 'alpha' },
    //                     'sequencing interaction description',
    //                     1,
    //                     0,
    //                     'strLearningObjectiveID'
    //                 );
    //             }}
    //         >
    //             RecordSequencingInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordLikertInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordLikertInteraction(
    //                     'abc087',
    //                     'response',
    //                     true,
    //                     'response',
    //                     'likert interaction description',
    //                     1,
    //                     0,
    //                     'strLearningObjectiveID'
    //                 );
    //             }}
    //         >
    //             RecordLikertInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    // createData(
    //     'RecordNumericInteraction()',
    //     <div>
    //         <button
    //             onClick={() => {
    //                 rxd?.RecordNumericInteraction(
    //                     'num_123',
    //                     1,
    //                     true,
    //                     1,
    //                     'numeric interaction description',
    //                     1,
    //                     1,
    //                     'lo_123'
    //                 );
    //             }}
    //         >
    //             RecordNumericInteraction
    //         </button>
    //     </div>,
    //     '--'
    // ),
    createData(
      'Determine the Account ID from query parameter',
      '--',
      <div>
        {new URLSearchParams(window.parent.location.search).get('accountId')}
      </div>,
    ),
    createData(
      'ConcedeControl()',
      <div>
        <button onClick={() => rxd?.ConcedeControl()}>
          ConcedeControl (Exit Course)
        </button>
      </div>,
      '--',
    ),
  ];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 250, overflow: 'overlay' }}>
        <Table sx={{ minWidth: 200 }} stickyHeader aria-label='sticky table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell width='400'>Function Name</StyledTableCell>
              <StyledTableCell width='400'>Test Action</StyledTableCell>
              <StyledTableCell width='400' align='left'>
                Result
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.functionName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell width='400' component='th' scope='row'>
                  {row.functionName}
                </StyledTableCell>
                <StyledTableCell width='400' align='left'>
                  {row.testAction}
                </StyledTableCell>
                <StyledTableCell width='400' align='left'>
                  {row.result}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

interface Props {
  rxd: any; // The RXD object of functions.
}

const RxdDebugger = (props: Props) => {
  const { rxd } = props;

  return (
    <Box style={{ width: '90%' }}>
      <h2 style={{ width: '100%' }}>RXD Debug Options</h2>
      <Box
        sx={{
          margin: '1rem 0',
          padding: '0.625rem',
          border: '1px solid #c1c1c1',
          backgroundColor: '#34363b',
        }}
      >
        {/* Reference: https://rustici-docs.s3.amazonaws.com/crossdomain/3.x/API.html */}
        <h3 style={{ marginTop: 0 }}>
          This is a piece of sample content served from a remote domain
          different than the domain of the launching LMS. This page is intended
          to show code examples of making use of the public interface of RXD
          when leveraging the contentAPI.html page.
        </h3>
        <BasicTable rxd={rxd} />
      </Box>
    </Box>
  );
};

export default RxdDebugger;
