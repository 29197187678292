import * as React from 'react';

function ConnectivityIssueIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || 'black'; // set to default as black for Storybook visibility
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0004 4.5H13.5004C10.5754 13.26 10.5754 22.74 13.5004 31.5H12.0004'
        stroke={color}
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 4.5C23.955 8.88 24.69 13.44 24.69 18'
        stroke={color}
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 24V22.5C8.88 23.955 13.44 24.69 18 24.69'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 13.5004C13.26 10.5754 22.74 10.5754 31.5 13.5004'
        stroke={color}
        strokeWidth='3.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 25L25.3 31.6'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M25.3008 25L32.0007 31.6'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}

const MemoConnectivityIssueIcon = React.memo(ConnectivityIssueIcon);
export default MemoConnectivityIssueIcon;
